/*!
 * Ace Admin Template v2.1.3
 * Copyright 2013-2020
 * You need a commercial license to use this product
 * https://bit.ly/35ciMLb
 */
.navbar-default {
  background-color: #2087c5;
}

.navbar-default .navbar-toggler:hover, .navbar-default .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-default .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-default .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.92);
  background-color: transparent;
}

.navbar-default .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-default .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-default .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-default .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.15);
}

@media (min-width: 992px) {
  .navbar-default .navbar-intro {
    background-color: rgba(0, 0, 0, 0.075);
  }
  .navbar-default .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-default .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-default .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-default .navbar-nav > .nav > .nav-item.show,
  .navbar-default .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-default .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-default .navbar-content-section input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-default .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-default .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-default .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-default .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-default .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-default .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-default .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-default .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-default .navbar-content-section input {
  background-color: transparent;
  color: #fff;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-default .navbar-content-section input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-default .navbar-content-section input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-default .navbar-content-section input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-default .navbar-content-section input::-moz-placeholder {
  color: #fff;
}

.navbar-white {
  background-color: #FFF;
}

.navbar-white .navbar-inner {
  border-bottom: 1px solid #eee;
}

.navbar-white .navbar-toggler:hover, .navbar-white .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-white .navbar-toggler[aria-expanded="true"] {
  background-color: #f0f5f9;
}

.navbar-white .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: #798ba4;
  background-color: transparent;
}

.navbar-white .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: #f0f5f9;
}

.navbar-white .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: #f0f5f9;
}

.navbar-white .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: #8eb8d9;
}

.navbar-white .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: #f0f5f9;
}

@media (min-width: 992px) {
  .navbar-white .navbar-intro {
    border-right: 1px solid #f0f0f0;
  }
  .navbar-white .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-white.navbar {
    background-color: #FFF;
  }
  .navbar-white .navbar-inner {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    border-bottom-width: 0;
  }
  .navbar-white .navbar-content-section input {
    border-bottom-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-white .navbar-collapse {
    border: 0px solid rgba(69, 124, 168, 0.13);
    border-width: 1px 0;
  }
  .navbar-white .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(69, 124, 168, 0.13);
  }
  .navbar-white .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(232, 239, 245, 0.5);
  }
  .navbar-white .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    margin-top: 1px;
  }
  .navbar-white .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-white .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link:hover {
    background-color: #FFF;
    color: #798ba4;
  }
  .navbar-white .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
    color: #798ba4;
  }
}

@media (min-width: 992px) {
  .navbar-white .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-white .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-white .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-white .navbar-content-section input {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.73);
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-white .navbar-content-section input:focus {
  border-color: rgba(0, 0, 0, 0.2);
}

.navbar-white .navbar-content-section input:not(:focus):hover {
  border-color: rgba(0, 0, 0, 0.125);
}

.navbar-white .navbar-content-section input::placeholder {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-white .navbar-content-section input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.73);
}

.navbar-white2 {
  background-color: #FFF;
}

.navbar-white2 .navbar-inner {
  border-bottom: 1px solid #eee;
}

.navbar-white2 .navbar-toggler:hover, .navbar-white2 .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-white2 .navbar-toggler[aria-expanded="true"] {
  background-color: #f0f5f9;
}

.navbar-white2 .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: #696d73;
  background-color: transparent;
}

.navbar-white2 .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: #f0f3f6;
}

.navbar-white2 .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: #f0f3f6;
}

.navbar-white2 .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: #a7b2ca;
}

.navbar-white2 .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  color: #687892;
  background-color: #f0f3f6;
}

@media (min-width: 992px) {
  .navbar-white2 .navbar-intro {
    border-right: 1px solid #f0f0f0;
  }
  .navbar-white2 .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-white2.navbar {
    background-color: #FFF;
  }
  .navbar-white2 .navbar-inner {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    border-bottom-width: 0;
  }
  .navbar-white2 .navbar-content-section input {
    border-bottom-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-white2 .navbar-collapse {
    border: 0px solid rgba(69, 124, 168, 0.13);
    border-width: 1px 0;
  }
  .navbar-white2 .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(69, 124, 168, 0.13);
  }
  .navbar-white2 .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(232, 239, 245, 0.5);
  }
  .navbar-white2 .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    margin-top: 1px;
  }
  .navbar-white2 .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-white2 .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link:hover {
    background-color: #FFF;
    color: #696d73;
  }
  .navbar-white2 .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
    color: #696d73;
  }
}

@media (min-width: 992px) {
  .navbar-white2 .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-white2 .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-white2 .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-white2 .navbar-content-section input {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.73);
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-white2 .navbar-content-section input:focus {
  border-color: rgba(0, 0, 0, 0.2);
}

.navbar-white2 .navbar-content-section input:not(:focus):hover {
  border-color: rgba(0, 0, 0, 0.125);
}

.navbar-white2 .navbar-content-section input::placeholder {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-white2 .navbar-content-section input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.73);
}

.navbar-light {
  background-color: whitesmoke;
}

.navbar-light .navbar-inner {
  border-bottom: 1px solid #eee;
}

.navbar-light .navbar-toggler:hover, .navbar-light .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-light .navbar-toggler[aria-expanded="true"] {
  background-color: #f0f5f9;
}

.navbar-light .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(0, 0, 0, 0.5);
  background-color: transparent;
}

.navbar-light .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(232, 239, 245, 0.5);
}

.navbar-light .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(232, 239, 245, 0.5);
}

.navbar-light .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(232, 239, 245, 0.5);
}

@media (min-width: 992px) {
  .navbar-light .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-light.navbar {
    background-color: #FFF;
  }
  .navbar-light .navbar-inner {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    border-bottom-width: 0;
  }
  .navbar-light .navbar-content-section input {
    border-bottom-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-light .navbar-collapse {
    border: 0px solid rgba(69, 124, 168, 0.13);
    border-width: 1px 0;
  }
  .navbar-light .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(69, 124, 168, 0.13);
  }
  .navbar-light .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: whitesmoke;
  }
  .navbar-light .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    margin-top: 1px;
  }
  .navbar-light .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-light .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link:hover {
    background-color: whitesmoke;
    color: rgba(0, 0, 0, 0.5);
  }
  .navbar-light .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.5);
  }
}

@media (min-width: 992px) {
  .navbar-light .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-light .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-light .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-light .navbar-content-section input {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.73);
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-light .navbar-content-section input:focus {
  border-color: rgba(0, 0, 0, 0.2);
}

.navbar-light .navbar-content-section input:not(:focus):hover {
  border-color: rgba(0, 0, 0, 0.125);
}

.navbar-light .navbar-content-section input::placeholder {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-content-section input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.73);
}

.navbar-lightblue {
  background-color: #f2f8fd;
}

.navbar-lightblue .navbar-inner {
  border-bottom: 1px solid #dfedf8;
}

.navbar-lightblue .navbar-toggler:hover, .navbar-lightblue .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-lightblue .navbar-toggler[aria-expanded="true"] {
  background-color: #f0f5f9;
}

.navbar-lightblue .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: #5688b0;
  background-color: transparent;
}

.navbar-lightblue .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  color: #fff;
  background-color: #7ea5c4;
}

.navbar-lightblue .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  color: #fff;
  background-color: #8aaeca;
}

.navbar-lightblue .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  color: #fff;
  background-color: #749ebf;
}

@media (min-width: 992px) {
  .navbar-lightblue .navbar-intro {
    background-color: #fdfeff;
    border-right: 1px solid #e5edf5;
  }
  .navbar-lightblue .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-lightblue.navbar {
    background-color: #FFF;
  }
  .navbar-lightblue .navbar-inner {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    border-bottom-width: 0;
  }
  .navbar-lightblue .navbar-content-section input {
    border-bottom-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-lightblue .navbar-collapse {
    border: 0px solid #dae5ee;
    border-width: 1px 0;
  }
  .navbar-lightblue .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid #dae5ee;
  }
  .navbar-lightblue .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: #f2f8fd;
  }
  .navbar-lightblue .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    margin-top: 1px;
  }
  .navbar-lightblue .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-lightblue .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link:hover {
    background-color: #f2f8fd;
    color: #5688b0;
  }
  .navbar-lightblue .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
    color: #5688b0;
  }
}

@media (min-width: 992px) {
  .navbar-lightblue .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-lightblue .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-lightblue .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-lightblue .navbar-content-section input {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.73);
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-lightblue .navbar-content-section input:focus {
  border-color: rgba(0, 0, 0, 0.2);
}

.navbar-lightblue .navbar-content-section input:not(:focus):hover {
  border-color: rgba(0, 0, 0, 0.125);
}

.navbar-lightblue .navbar-content-section input::placeholder {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-lightblue .navbar-content-section input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.73);
}

.navbar-lightpurple {
  background-color: #f2f1f9;
}

.navbar-lightpurple .navbar-inner {
  border-bottom: 1px solid #e8e5f0;
}

.navbar-lightpurple .navbar-toggler:hover, .navbar-lightpurple .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-lightpurple .navbar-toggler[aria-expanded="true"] {
  background-color: #f0f5f9;
}

.navbar-lightpurple .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: #756999;
  background-color: transparent;
}

.navbar-lightpurple .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  color: #fff;
  background-color: #a091c8;
}

.navbar-lightpurple .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  color: #fff;
  background-color: #a698cc;
}

.navbar-lightpurple .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  color: #fff;
  background-color: #9d8ec7;
}

@media (min-width: 992px) {
  .navbar-lightpurple .navbar-intro {
    background-color: #fdfdfe;
    border-right: 1px solid #efedf5;
  }
  .navbar-lightpurple .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-lightpurple.navbar {
    background-color: #FFF;
  }
  .navbar-lightpurple .navbar-inner {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    border-bottom-width: 0;
  }
  .navbar-lightpurple .navbar-content-section input {
    border-bottom-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-lightpurple .navbar-collapse {
    border: 0px solid #f2eff7;
    border-width: 1px 0;
  }
  .navbar-lightpurple .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid #f2eff7;
  }
  .navbar-lightpurple .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: #f2f1f9;
  }
  .navbar-lightpurple .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    margin-top: 1px;
  }
  .navbar-lightpurple .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-lightpurple .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link:hover {
    background-color: #f2f1f9;
    color: #756999;
  }
  .navbar-lightpurple .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
    color: #756999;
  }
}

@media (min-width: 992px) {
  .navbar-lightpurple .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-lightpurple .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-lightpurple .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-lightpurple .navbar-content-section input {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.73);
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-lightpurple .navbar-content-section input:focus {
  border-color: rgba(0, 0, 0, 0.2);
}

.navbar-lightpurple .navbar-content-section input:not(:focus):hover {
  border-color: rgba(0, 0, 0, 0.125);
}

.navbar-lightpurple .navbar-content-section input::placeholder {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-lightpurple .navbar-content-section input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.73);
}

.navbar-lightgreen {
  background-color: #effced;
}

.navbar-lightgreen .navbar-inner {
  border-bottom: 1px solid #e7f1e5;
}

.navbar-lightgreen .navbar-toggler:hover, .navbar-lightgreen .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-lightgreen .navbar-toggler[aria-expanded="true"] {
  background-color: #f0f5f9;
}

.navbar-lightgreen .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: #717b75;
  background-color: transparent;
}

.navbar-lightgreen .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  color: #fff;
  background-color: #85c28c;
}

.navbar-lightgreen .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  color: #fff;
  background-color: #85c28c;
}

.navbar-lightgreen .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  color: #fff;
  background-color: #74ba7c;
}

@media (min-width: 992px) {
  .navbar-lightgreen .navbar-intro {
    background-color: #fcfffc;
    border-right: 1px solid #e7f1e5;
  }
  .navbar-lightgreen .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-lightgreen.navbar {
    background-color: #FFF;
  }
  .navbar-lightgreen .navbar-inner {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    border-bottom-width: 0;
  }
  .navbar-lightgreen .navbar-content-section input {
    border-bottom-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-lightgreen .navbar-collapse {
    border: 0px solid #eff7f0;
    border-width: 1px 0;
  }
  .navbar-lightgreen .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid #eff7f0;
  }
  .navbar-lightgreen .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: #effced;
  }
  .navbar-lightgreen .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    margin-top: 1px;
  }
  .navbar-lightgreen .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-lightgreen .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link:hover {
    background-color: #effced;
    color: #717b75;
  }
  .navbar-lightgreen .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
    color: #717b75;
  }
}

@media (min-width: 992px) {
  .navbar-lightgreen .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-lightgreen .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-lightgreen .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-lightgreen .navbar-content-section input {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.73);
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-lightgreen .navbar-content-section input:focus {
  border-color: rgba(0, 0, 0, 0.2);
}

.navbar-lightgreen .navbar-content-section input:not(:focus):hover {
  border-color: rgba(0, 0, 0, 0.125);
}

.navbar-lightgreen .navbar-content-section input::placeholder {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-lightgreen .navbar-content-section input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.73);
}

.navbar-lightgrey {
  background-color: #f4f5f7;
}

.navbar-lightgrey .navbar-toggler:hover, .navbar-lightgrey .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-lightgrey .navbar-toggler[aria-expanded="true"] {
  background-color: #f0f5f9;
}

.navbar-lightgrey .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: #6f747c;
  background-color: transparent;
}

.navbar-lightgrey .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  color: #fff;
  background-color: #99a3a8;
}

.navbar-lightgrey .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  color: #fff;
  background-color: #99a3a8;
}

.navbar-lightgrey .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  color: #fff;
  background-color: #8d9fa7;
}

@media (min-width: 992px) {
  .navbar-lightgrey .navbar-intro {
    background-color: #3cb371;
  }
  .navbar-lightgrey .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-lightgrey.navbar {
    background-color: #FFF;
  }
  .navbar-lightgrey .navbar-inner {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    border-bottom-width: 0;
  }
  .navbar-lightgrey .navbar-content-section input {
    border-bottom-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-lightgrey .navbar-collapse {
    border: 0px solid #f1f3f3;
    border-width: 1px 0;
  }
  .navbar-lightgrey .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid #f1f3f3;
  }
  .navbar-lightgrey .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: #f4f5f7;
  }
  .navbar-lightgrey .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    margin-top: 1px;
  }
  .navbar-lightgrey .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-lightgrey .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link:hover {
    background-color: #f4f5f7;
    color: #6f747c;
  }
  .navbar-lightgrey .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
    color: #6f747c;
  }
}

@media (min-width: 992px) {
  .navbar-lightgrey .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-lightgrey .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-lightgrey .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-lightgrey .navbar-content-section input {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.73);
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-lightgrey .navbar-content-section input:focus {
  border-color: rgba(0, 0, 0, 0.2);
}

.navbar-lightgrey .navbar-content-section input:not(:focus):hover {
  border-color: rgba(0, 0, 0, 0.125);
}

.navbar-lightgrey .navbar-content-section input::placeholder {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-lightgrey .navbar-content-section input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.73);
}

.navbar-lightyellow {
  background-color: #fffcdc;
}

.navbar-lightyellow .navbar-inner {
  border-bottom: 1px solid #fbf3c9;
}

.navbar-lightyellow .navbar-toggler:hover, .navbar-lightyellow .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-lightyellow .navbar-toggler[aria-expanded="true"] {
  background-color: #f0f5f9;
}

.navbar-lightyellow .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(0, 0, 0, 0.6);
  background-color: transparent;
}

.navbar-lightyellow .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  color: #fff;
  background-color: #b89a8b;
}

.navbar-lightyellow .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  color: #fff;
  background-color: #b89a8b;
}

.navbar-lightyellow .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  color: #fff;
  background-color: #bc8e80;
}

@media (min-width: 992px) {
  .navbar-lightyellow .navbar-intro {
    background-color: #f8f0ad;
  }
  .navbar-lightyellow .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-lightyellow.navbar {
    background-color: #FFF;
  }
  .navbar-lightyellow .navbar-inner {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    border-bottom-width: 0;
  }
  .navbar-lightyellow .navbar-content-section input {
    border-bottom-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-lightyellow .navbar-collapse {
    border: 0px solid #f6f2f0;
    border-width: 1px 0;
  }
  .navbar-lightyellow .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid #f6f2f0;
  }
  .navbar-lightyellow .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: #fffcdc;
  }
  .navbar-lightyellow .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    margin-top: 1px;
  }
  .navbar-lightyellow .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-lightyellow .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link:hover {
    background-color: #fffcdc;
    color: rgba(0, 0, 0, 0.6);
  }
  .navbar-lightyellow .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.6);
  }
}

@media (min-width: 992px) {
  .navbar-lightyellow .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-lightyellow .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-lightyellow .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-lightyellow .navbar-content-section input {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.73);
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-lightyellow .navbar-content-section input:focus {
  border-color: rgba(0, 0, 0, 0.2);
}

.navbar-lightyellow .navbar-content-section input:not(:focus):hover {
  border-color: rgba(0, 0, 0, 0.125);
}

.navbar-lightyellow .navbar-content-section input::placeholder {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-lightyellow .navbar-content-section input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.73);
}

.navbar-khaki {
  background-color: khaki;
}

.navbar-khaki .navbar-toggler:hover, .navbar-khaki .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-khaki .navbar-toggler[aria-expanded="true"] {
  background-color: #f0f5f9;
}

.navbar-khaki .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: #7c6348;
  background-color: transparent;
}

.navbar-khaki .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  color: #fff;
  background-color: #b89a8b;
}

.navbar-khaki .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  color: #fff;
  background-color: #b89a8b;
}

.navbar-khaki .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  color: #fff;
  background-color: #bc8e80;
}

@media (min-width: 992px) {
  .navbar-khaki .navbar-intro {
    background-color: #e1d56e;
  }
  .navbar-khaki .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-khaki .navbar-content-section input {
    border-bottom-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-khaki .navbar-collapse {
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-width: 1px 0;
  }
  .navbar-khaki .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  .navbar-khaki .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: #bc8e80;
  }
  .navbar-khaki .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-khaki .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-khaki .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-khaki .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-khaki .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-khaki .navbar-content-section input {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.73);
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-khaki .navbar-content-section input:focus {
  border-color: rgba(0, 0, 0, 0.2);
}

.navbar-khaki .navbar-content-section input:not(:focus):hover {
  border-color: rgba(0, 0, 0, 0.125);
}

.navbar-khaki .navbar-content-section input::placeholder {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-khaki .navbar-content-section input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.73);
}

.navbar-dark {
  background-color: #565f68;
}

.navbar-dark .navbar-toggler:hover, .navbar-dark .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-dark .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.92);
  background-color: transparent;
}

.navbar-dark .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-dark .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-dark .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.15);
}

@media (min-width: 992px) {
  .navbar-dark .navbar-intro {
    background-color: rgba(0, 0, 0, 0.075);
  }
  .navbar-dark .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-dark .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-dark .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-dark .navbar-nav > .nav > .nav-item.show,
  .navbar-dark .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-dark .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-dark .navbar-content-section input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-dark .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-dark .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-dark .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-dark .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-dark .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-dark .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-dark .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-dark .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-dark .navbar-content-section input {
  background-color: transparent;
  color: #fff;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-dark .navbar-content-section input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-dark .navbar-content-section input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-dark .navbar-content-section input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-dark .navbar-content-section input::-moz-placeholder {
  color: #fff;
}

.navbar-color {
  background-color: #565f68;
}

.navbar-color .navbar-toggler:hover, .navbar-color .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-color .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-color .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.95);
  background-color: transparent;
}

.navbar-color .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.125);
}

.navbar-color .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-color .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-color .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .navbar-color .navbar-intro {
    background-color: rgba(0, 0, 0, 0.075);
  }
  .navbar-color .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-color .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-color .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-color .navbar-nav > .nav > .nav-item.show,
  .navbar-color .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-color .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-color .navbar-content-section input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-color .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-color .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-color .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-color .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-color .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-color .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-color .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-color .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-color .navbar-content-section input {
  background-color: transparent;
  color: #fff;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-color .navbar-content-section input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-color .navbar-content-section input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-color .navbar-content-section input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-color .navbar-content-section input::-moz-placeholder {
  color: #fff;
}

.navbar-purple {
  background-color: #7b6cb5;
}

.navbar-purple .navbar-toggler:hover, .navbar-purple .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-purple .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-purple .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.95);
  background-color: transparent;
}

.navbar-purple .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.125);
}

.navbar-purple .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-purple .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-purple .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .navbar-purple .navbar-intro {
    background-color: rgba(0, 0, 0, 0.075);
  }
  .navbar-purple .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-purple .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-purple .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-purple .navbar-nav > .nav > .nav-item.show,
  .navbar-purple .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-purple .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-purple .navbar-content-section input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-purple .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-purple .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-purple .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-purple .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-purple .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-purple .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-purple .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-purple .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-purple .navbar-content-section input {
  background-color: transparent;
  color: #fff;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-purple .navbar-content-section input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-purple .navbar-content-section input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-purple .navbar-content-section input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-purple .navbar-content-section input::-moz-placeholder {
  color: #fff;
}

.navbar-plum {
  background-color: #8a5188;
}

.navbar-plum .navbar-toggler:hover, .navbar-plum .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-plum .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-plum .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.95);
  background-color: transparent;
}

.navbar-plum .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.125);
}

.navbar-plum .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-plum .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-plum .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .navbar-plum .navbar-intro {
    background-color: rgba(0, 0, 0, 0.075);
  }
  .navbar-plum .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-plum .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-plum .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-plum .navbar-nav > .nav > .nav-item.show,
  .navbar-plum .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-plum .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-plum .navbar-content-section input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-plum .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-plum .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-plum .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-plum .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-plum .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-plum .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-plum .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-plum .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-plum .navbar-content-section input {
  background-color: transparent;
  color: #fff;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-plum .navbar-content-section input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-plum .navbar-content-section input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-plum .navbar-content-section input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-plum .navbar-content-section input::-moz-placeholder {
  color: #fff;
}

.navbar-secondary {
  background-color: #91a7b4;
}

.navbar-secondary .navbar-toggler:hover, .navbar-secondary .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-secondary .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-secondary .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.95);
  background-color: transparent;
}

.navbar-secondary .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.125);
}

.navbar-secondary .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-secondary .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-secondary .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .navbar-secondary .navbar-intro {
    background-color: rgba(0, 0, 0, 0.075);
  }
  .navbar-secondary .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-secondary .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-secondary .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-secondary .navbar-nav > .nav > .nav-item.show,
  .navbar-secondary .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-secondary .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-secondary .navbar-content-section input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-secondary .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-secondary .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-secondary .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-secondary .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-secondary .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-secondary .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-secondary .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-secondary .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-secondary .navbar-content-section input {
  background-color: transparent;
  color: #fff;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-secondary .navbar-content-section input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-secondary .navbar-content-section input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-secondary .navbar-content-section input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-secondary .navbar-content-section input::-moz-placeholder {
  color: #fff;
}

.navbar-teal {
  background-color: teal;
}

.navbar-teal .navbar-toggler:hover, .navbar-teal .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-teal .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-teal .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.95);
  background-color: transparent;
}

.navbar-teal .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.125);
}

.navbar-teal .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-teal .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-teal .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .navbar-teal .navbar-intro {
    background-color: rgba(0, 0, 0, 0.075);
  }
  .navbar-teal .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-teal .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-teal .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-teal .navbar-nav > .nav > .nav-item.show,
  .navbar-teal .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-teal .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-teal .navbar-content-section input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-teal .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-teal .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-teal .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-teal .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-teal .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-teal .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-teal .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-teal .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-teal .navbar-content-section input {
  background-color: transparent;
  color: #fff;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-teal .navbar-content-section input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-teal .navbar-content-section input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-teal .navbar-content-section input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-teal .navbar-content-section input::-moz-placeholder {
  color: #fff;
}

.navbar-steelblue {
  background-color: #4182b8;
}

.navbar-steelblue .navbar-toggler:hover, .navbar-steelblue .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-steelblue .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-steelblue .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.95);
  background-color: transparent;
}

.navbar-steelblue .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.125);
}

.navbar-steelblue .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-steelblue .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-steelblue .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .navbar-steelblue .navbar-intro {
    background-color: rgba(0, 0, 0, 0.075);
  }
  .navbar-steelblue .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-steelblue .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-steelblue .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-steelblue .navbar-nav > .nav > .nav-item.show,
  .navbar-steelblue .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-steelblue .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-steelblue .navbar-content-section input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-steelblue .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-steelblue .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-steelblue .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-steelblue .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-steelblue .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-steelblue .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-steelblue .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-steelblue .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-steelblue .navbar-content-section input {
  background-color: transparent;
  color: #fff;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-steelblue .navbar-content-section input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-steelblue .navbar-content-section input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-steelblue .navbar-content-section input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-steelblue .navbar-content-section input::-moz-placeholder {
  color: #fff;
}

.navbar-slategrey {
  background-color: slategrey;
}

.navbar-slategrey .navbar-toggler:hover, .navbar-slategrey .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-slategrey .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-slategrey .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.95);
  background-color: transparent;
}

.navbar-slategrey .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.125);
}

.navbar-slategrey .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-slategrey .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-slategrey .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .navbar-slategrey .navbar-intro {
    background-color: rgba(0, 0, 0, 0.075);
  }
  .navbar-slategrey .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-slategrey .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-slategrey .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-slategrey .navbar-nav > .nav > .nav-item.show,
  .navbar-slategrey .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-slategrey .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-slategrey .navbar-content-section input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-slategrey .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-slategrey .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-slategrey .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-slategrey .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-slategrey .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-slategrey .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-slategrey .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-slategrey .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-slategrey .navbar-content-section input {
  background-color: transparent;
  color: #fff;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-slategrey .navbar-content-section input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-slategrey .navbar-content-section input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-slategrey .navbar-content-section input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-slategrey .navbar-content-section input::-moz-placeholder {
  color: #fff;
}

.navbar-mediumseagreen {
  background-color: mediumseagreen;
}

.navbar-mediumseagreen .navbar-toggler:hover, .navbar-mediumseagreen .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-mediumseagreen .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-mediumseagreen .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.95);
  background-color: transparent;
}

.navbar-mediumseagreen .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.125);
}

.navbar-mediumseagreen .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-mediumseagreen .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-mediumseagreen .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .navbar-mediumseagreen .navbar-intro {
    background-color: rgba(0, 0, 0, 0.075);
  }
  .navbar-mediumseagreen .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-mediumseagreen .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-mediumseagreen .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-mediumseagreen .navbar-nav > .nav > .nav-item.show,
  .navbar-mediumseagreen .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-mediumseagreen .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-mediumseagreen .navbar-content-section input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-mediumseagreen .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-mediumseagreen .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-mediumseagreen .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-mediumseagreen .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-mediumseagreen .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-mediumseagreen .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-mediumseagreen .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-mediumseagreen .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-mediumseagreen .navbar-content-section input {
  background-color: transparent;
  color: #fff;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-mediumseagreen .navbar-content-section input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-mediumseagreen .navbar-content-section input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-mediumseagreen .navbar-content-section input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-mediumseagreen .navbar-content-section input::-moz-placeholder {
  color: #fff;
}

.navbar-cadetblue {
  background-color: cadetblue;
}

.navbar-cadetblue .navbar-toggler:hover, .navbar-cadetblue .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-cadetblue .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-cadetblue .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.95);
  background-color: transparent;
}

.navbar-cadetblue .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.125);
}

.navbar-cadetblue .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-cadetblue .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-cadetblue .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .navbar-cadetblue .navbar-intro {
    background-color: rgba(0, 0, 0, 0.075);
  }
  .navbar-cadetblue .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-cadetblue .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-cadetblue .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-cadetblue .navbar-nav > .nav > .nav-item.show,
  .navbar-cadetblue .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-cadetblue .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-cadetblue .navbar-content-section input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-cadetblue .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-cadetblue .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-cadetblue .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-cadetblue .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-cadetblue .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-cadetblue .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-cadetblue .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-cadetblue .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-cadetblue .navbar-content-section input {
  background-color: transparent;
  color: #fff;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-cadetblue .navbar-content-section input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-cadetblue .navbar-content-section input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-cadetblue .navbar-content-section input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-cadetblue .navbar-content-section input::-moz-placeholder {
  color: #fff;
}

.navbar-darkseagreen {
  background-color: darkseagreen;
}

.navbar-darkseagreen .navbar-toggler:hover, .navbar-darkseagreen .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-darkseagreen .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-darkseagreen .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.95);
  background-color: transparent;
}

.navbar-darkseagreen .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.125);
}

.navbar-darkseagreen .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-darkseagreen .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-darkseagreen .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .navbar-darkseagreen .navbar-intro {
    background-color: rgba(0, 0, 0, 0.075);
  }
  .navbar-darkseagreen .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-darkseagreen .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-darkseagreen .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-darkseagreen .navbar-nav > .nav > .nav-item.show,
  .navbar-darkseagreen .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-darkseagreen .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-darkseagreen .navbar-content-section input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-darkseagreen .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-darkseagreen .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-darkseagreen .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-darkseagreen .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-darkseagreen .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-darkseagreen .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-darkseagreen .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-darkseagreen .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-darkseagreen .navbar-content-section input {
  background-color: transparent;
  color: #fff;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-darkseagreen .navbar-content-section input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-darkseagreen .navbar-content-section input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-darkseagreen .navbar-content-section input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-darkseagreen .navbar-content-section input::-moz-placeholder {
  color: #fff;
}

.navbar-lightslategrey {
  background-color: lightslategrey;
}

.navbar-lightslategrey .navbar-toggler:hover, .navbar-lightslategrey .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-lightslategrey .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-lightslategrey .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.95);
  background-color: transparent;
}

.navbar-lightslategrey .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.125);
}

.navbar-lightslategrey .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-lightslategrey .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-lightslategrey .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .navbar-lightslategrey .navbar-intro {
    background-color: rgba(0, 0, 0, 0.075);
  }
  .navbar-lightslategrey .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-lightslategrey .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-lightslategrey .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-lightslategrey .navbar-nav > .nav > .nav-item.show,
  .navbar-lightslategrey .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-lightslategrey .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-lightslategrey .navbar-content-section input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-lightslategrey .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-lightslategrey .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-lightslategrey .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-lightslategrey .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-lightslategrey .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-lightslategrey .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-lightslategrey .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-lightslategrey .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-lightslategrey .navbar-content-section input {
  background-color: transparent;
  color: #fff;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-lightslategrey .navbar-content-section input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-lightslategrey .navbar-content-section input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-lightslategrey .navbar-content-section input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-lightslategrey .navbar-content-section input::-moz-placeholder {
  color: #fff;
}

.navbar-orange {
  background-color: #eda449;
}

.navbar-orange .navbar-toggler:hover, .navbar-orange .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-orange .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-orange .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.95);
  background-color: transparent;
}

.navbar-orange .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.125);
}

.navbar-orange .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-orange .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-orange .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .navbar-orange .navbar-intro {
    background-color: rgba(0, 0, 0, 0.075);
  }
  .navbar-orange .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-orange .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-orange .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-orange .navbar-nav > .nav > .nav-item.show,
  .navbar-orange .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-orange .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-orange .navbar-content-section input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-orange .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-orange .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-orange .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-orange .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-orange .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-orange .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-orange .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-orange .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-orange .navbar-content-section input {
  background-color: transparent;
  color: #fff;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-orange .navbar-content-section input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-orange .navbar-content-section input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-orange .navbar-content-section input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-orange .navbar-content-section input::-moz-placeholder {
  color: #fff;
}

.navbar-burlywood {
  background-color: #dab07b;
}

.navbar-burlywood .navbar-toggler:hover, .navbar-burlywood .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-burlywood .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-burlywood .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: #fff;
  background-color: transparent;
}

.navbar-burlywood .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-burlywood .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-burlywood .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-burlywood .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .navbar-burlywood .navbar-intro {
    background-color: rgba(0, 0, 0, 0.075);
  }
  .navbar-burlywood .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-burlywood .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-burlywood .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-burlywood .navbar-nav > .nav > .nav-item.show,
  .navbar-burlywood .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-burlywood .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-burlywood .navbar-content-section input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-burlywood .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-burlywood .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-burlywood .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-burlywood .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-burlywood .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-burlywood .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-burlywood .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-burlywood .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-burlywood .navbar-content-section input {
  background-color: transparent;
  color: #fff;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-burlywood .navbar-content-section input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-burlywood .navbar-content-section input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-burlywood .navbar-content-section input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-burlywood .navbar-content-section input::-moz-placeholder {
  color: #fff;
}

.navbar-skyblue {
  background-color: #63b1e0;
}

.navbar-skyblue .navbar-toggler:hover, .navbar-skyblue .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-skyblue .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-skyblue .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: #fff;
  background-color: transparent;
}

.navbar-skyblue .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.87);
  background-color: rgba(0, 0, 0, 0.125);
}

.navbar-skyblue .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  color: rgba(255, 255, 255, 0.93);
  background-color: rgba(0, 0, 0, 0.15);
}

.navbar-skyblue .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-skyblue .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.15);
}

@media (min-width: 992px) {
  .navbar-skyblue .navbar-intro {
    background-color: #5aa0cb;
  }
  .navbar-skyblue .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-skyblue .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-skyblue .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-skyblue .navbar-nav > .nav > .nav-item.show,
  .navbar-skyblue .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-skyblue .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-skyblue .navbar-content-section input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-skyblue .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-skyblue .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
  .navbar-skyblue .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-skyblue .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-skyblue .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-skyblue .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-skyblue .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-skyblue .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-skyblue .navbar-content-section input {
  background-color: transparent;
  color: #fff;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-skyblue .navbar-content-section input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-skyblue .navbar-content-section input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-skyblue .navbar-content-section input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-skyblue .navbar-content-section input::-moz-placeholder {
  color: #fff;
}

.navbar-blue {
  background-color: #5fb1e6;
}

.navbar-blue .navbar-toggler:hover, .navbar-blue .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-blue .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-blue .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: #fff;
  background-color: transparent;
}

.navbar-blue .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.87);
  background-color: rgba(0, 0, 0, 0.125);
}

.navbar-blue .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  color: rgba(255, 255, 255, 0.93);
  background-color: rgba(0, 0, 0, 0.15);
}

.navbar-blue .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-blue .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.15);
}

@media (min-width: 992px) {
  .navbar-blue .navbar-intro {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-blue .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-blue .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-blue .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-blue .navbar-nav > .nav > .nav-item.show,
  .navbar-blue .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-blue .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-blue .navbar-content-section input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-blue .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-blue .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
  .navbar-blue .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-blue .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-blue .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-blue .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-blue .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-blue .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-blue .navbar-content-section input {
  background-color: transparent;
  color: #fff;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-blue .navbar-content-section input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-blue .navbar-content-section input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-blue .navbar-content-section input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-blue .navbar-content-section input::-moz-placeholder {
  color: #fff;
}

.sidebar-default {
  background-color: #f7f7f7;
}

.sidebar-default .sidebar-inner {
  background: inherit;
  box-shadow: inset -1px 0 0 0 #dbdfe2;
}

.sidebar-default.toggling.has-open:not(.collapsed) .sidebar-inner {
  border-right: 1px solid #dbdfe2;
}

@media (min-width: 1200px) {
  .sidebar-default:not(.sidebar-h) .nav {
    position: relative;
    left: -1px;
  }
  .sidebar-default:not(.sidebar-h) .nav > .nav-item.active:not(.open) > .nav-link {
    left: 1px;
  }
  .sidebar-default:not(.sidebar-h) .nav.has-active-border > .nav-item.open > .nav-link::before,
  .sidebar-default:not(.sidebar-h) .nav.has-active-border .submenu .nav-link::before {
    margin-left: 1px;
  }
  .sidebar-default.hoverable.collapsed:not(.sidebar-h) .nav {
    left: auto;
  }
  .sidebar-default.hoverable.collapsed:not(.sidebar-h) .nav .nav-item > .nav-link {
    left: auto;
  }
  .sidebar-default.hoverable.collapsed:not(.sidebar-h) .nav.has-active-border > .nav-item > .nav-link::before,
  .sidebar-default.hoverable.collapsed:not(.sidebar-h) .nav.has-active-border .submenu .nav-link::before {
    margin-left: auto;
  }
}

@media (min-width: 1200px) {
  .sidebar-default.sidebar-h .nav > .nav-item.active > .nav-link {
    top: 1px;
  }
  .sidebar-default.sidebar-h .nav.active-on-right > .nav-item::before, .sidebar-default.sidebar-h .nav.active-on-right > .nav-item > .nav-link::before {
    bottom: auto !important;
    top: 0 !important;
  }
  .sidebar-default.sidebar-h .align-items-xl-end .nav.active-on-right > .nav-item::before, .sidebar-default.sidebar-h .align-items-xl-end .nav.active-on-right > .nav-item > .nav-link::before {
    top: -1px !important;
  }
}

@media (max-width: 1199.98px) {
  .sidebar-default.sidebar-push .nav {
    position: relative;
    left: -1px;
  }
  .sidebar-default.sidebar-push .nav > .nav-item.active:not(.open) > .nav-link {
    left: 1px;
  }
  .sidebar-default.sidebar-push .nav.has-active-border > .nav-item.open > .nav-link::before,
  .sidebar-default.sidebar-push .nav.has-active-border .submenu .nav-link::before {
    margin-left: 1px;
  }
}

@media (max-width: 1199.98px) {
  .sidebar-default:not(.sidebar-push) .sidebar-inner {
    box-shadow: none;
    border-right: 1px solid #d0d5d9;
  }
  .sidebar-default:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #d0d5d9;
  }
}

.sidebar-default .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-default .nav > .nav-item > .nav-link {
  color: #4e4e4e;
  font-size: 1rem;
}

.sidebar-default .nav > .nav-item > .nav-link > .nav-icon {
  color: #5c5c5c;
  font-size: 1.25em;
}

.sidebar-default .nav > .nav-item.open > .nav-link {
  color: #313131;
}

.sidebar-default.sidebar-spaced .nav > .nav-item.open:not(.active) > .nav-link {
  background-color: #e7eaef;
}

.sidebar-default .nav > .nav-item.open > .nav-link > .nav-icon {
  color: #4a4a4a;
}

.sidebar-default .nav > .nav-item > .nav-link:hover {
  background-color: #e7eaef;
  color: #2e2f30;
}

.sidebar-default .nav > .nav-item > .nav-link:hover > .nav-icon {
  color: #454647;
}

.sidebar-default .nav > .nav-item > .submenu {
  background-color: #fdfdfd;
}

.sidebar-default .submenu .nav-link {
  color: #616161;
  font-size: 0.95rem;
}

.sidebar-default .submenu .nav-item.open > .nav-link {
  color: #2a7ab8;
}

.sidebar-default .submenu .nav-item > .nav-link:hover {
  color: #3576a9;
  background-color: #f0f5f9;
}

.sidebar-default .submenu .nav-icon {
  color: #99a0a8;
  font-size: 1.1875rem;
}

.sidebar-default .submenu .nav-link:hover > .nav-icon {
  color: #92afd0;
}

.sidebar-default .submenu .nav-item.open > .nav-link > .nav-icon {
  color: #92afd0;
}

.sidebar-default .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-default .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-default .open > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-default .nav-item > .nav-link:hover > .caret {
  opacity: 0.82;
}

.sidebar-default .nav-item.active > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-default .nav-item.active.open > .nav-link > .caret {
  opacity: 0.92;
}

.sidebar-default .nav > .nav-item.active {
  border: 1px solid #dbdfe2;
  border-width: 1px 0;
}

@media (min-width: 1200px) {
  .sidebar-default.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-default .nav > .nav-item.active > .nav-link {
  color: #3c7db9;
  background-color: #fff;
}

.sidebar-default .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-default .nav > .nav-item.active > .nav-link > .nav-icon {
  color: #427fb8;
}

.sidebar-default .submenu .nav-item.active > .nav-link {
  color: #2a7ab8;
}

.sidebar-default .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-default .submenu .nav-item.active:not(.open) > .nav-link {
  background-color: #f0f5f9;
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-default.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-default.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #2a7ab8;
    background-color: #f0f5f9;
    font-weight: 600;
  }
  .sidebar-default.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-default.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-default .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: #4b95ce;
  top: -1px;
  bottom: -1px;
}

.sidebar-default .submenu .nav-link {
  padding-left: 3.75em;
}

.sidebar-default .submenu .submenu .nav-link {
  padding-left: 4.675em;
}

.sidebar-default .submenu .submenu .submenu .nav-link {
  padding-left: 5.325em;
}

.sidebar-default .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-default .submenu .submenu .nav-text::before {
  left: 3.675em;
}

.sidebar-default .submenu .submenu .submenu .nav-text::before {
  left: 4.325em;
}

.sidebar-default .submenu .nav-item > .nav-link:hover > .nav-text::before {
  content: "";
  display: block;
  opacity: 0.8;
}

.sidebar-default .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-default.hoverable.collapsed .submenu .nav-text::before, .sidebar-default.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

.sidebar-default .nav-item-caption {
  color: #717580;
  font-size: 0.875rem;
}

.sidebar-default .nav-item-divider {
  border-top: 1px solid #dde3e6;
}

.sidebar-default .submenu .nav-item-divider {
  border-top-color: #eceff1;
}

.sidebar-default .nav > .nav-item > .submenu > .submenu-inner {
  border: 1px solid #e6e9eb;
  border-width: 1px 0;
}

.sidebar-default .nav > .nav-item.active > .submenu > .submenu-inner {
  border-bottom-width: 0;
}

.sidebar-default .nav > .nav-item.open + .nav-item.active,
.sidebar-default .nav > .nav-item.is-toggling + .nav-item.active {
  margin-top: -2px;
}

.sidebar-default .ace-scroll {
  scrollbar-color: #9dbad5 #dce3ea;
}

.sidebar-default .ace-scroll::-webkit-scrollbar-track {
  background-color: #dce3ea;
  border-right: 1px solid #f7f7f7;
}

.sidebar-default .ace-scroll::-webkit-scrollbar-thumb {
  background-color: #9dbad5;
  border-right: 1px solid #f7f7f7;
}

.sidebar-default.sidebar-spaced .nav {
  margin-left: 0;
}

.sidebar-default.sidebar-spaced .nav > .nav-item.active:not(.open) > .nav-link {
  left: auto;
}

.sidebar-default.sidebar-spaced .nav > .nav-item.active {
  border-width: 1px;
  border-color: #d0d5d9;
}

@media (min-width: 1200px) {
  .sidebar-default.toggling .nav > .nav-item.active.open {
    transition: none;
  }
  .sidebar-default.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active), .sidebar-default.hoverable.collapsed .nav > .nav-item.open:not(.active) {
    border-color: transparent;
    background-color: transparent;
  }
  .sidebar-default.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link, .sidebar-default.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link {
    color: #4e4e4e;
    background-color: transparent;
  }
  .sidebar-default.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon, .sidebar-default.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon {
    color: #5c5c5c;
  }
  .sidebar-default.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open, .sidebar-default.hoverable.collapsed .nav > .nav-item.active.open {
    border-width: 1px 0;
    transition: none;
  }
}

.sidebar-default .sidebar-search-input {
  color: rgba(68, 80, 92, 0.9);
  border-bottom-color: rgba(204, 204, 204, 0.4);
}

.sidebar-default .sidebar-search-input:focus {
  border-bottom-color: rgba(140, 174, 213, 0.73);
}

.sidebar-default .sidebar-search-input::placeholder {
  color: rgba(129, 131, 136, 0.5);
}

.sidebar-default .sidebar-search-input::-moz-placeholder {
  color: #818388;
}

.sidebar-light {
  background-color: #f5f5f6;
}

.sidebar-light .sidebar-inner {
  border-right: 1px solid #e2e2e4;
  background: inherit;
}

@media (min-width: 1200px) {
  .sidebar-light.sidebar-hover:not(.collapsed) .sidebar-inner {
    box-shadow: 1px 0 0 0 #e2e2e4;
    border-right-width: 0;
  }
}

@media (max-width: 1199.98px) {
  .sidebar-light:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #e2e2e4;
  }
}

.sidebar-light .nav > .nav-item::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -1px;
  left: 1rem;
  right: 1rem;
  height: 0;
  width: auto;
  border: 0px solid #dfe3e8;
  border-width: 0 0 1px 0;
}

@media (min-width: 1200px) {
  .sidebar-light.sidebar-h .nav > .nav-item::after {
    display: none;
    /**
                border-width: 0 $item-border-size 0 0;
                $margin: map-get($sidebar, 'item-border-margin');
                @if $margin == null { $margin: map-get($sidebar-common, 'item-border-margin'); }
                left: auto;
                right: -2px;

                top: $margin * 0.75;
                bottom: $margin * 0.75;

                height: auto;
                width: 0;
                */
  }
}

.sidebar-light .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-light .nav > .nav-item > .nav-link {
  color: #55595d;
  font-size: 0.9625rem;
}

.sidebar-light .nav > .nav-item > .nav-link > .nav-icon {
  color: #7092b8;
  font-size: 1.25em;
}

.sidebar-light .nav > .nav-item.open > .nav-link {
  color: #3d78b1;
  background-color: #fff;
}

.sidebar-light .nav > .nav-item > .nav-link:hover {
  background-color: #fff;
  color: #3d78b1;
}

.sidebar-light .nav > .nav-item > .nav-link:hover > .nav-icon {
  color: #7092b8;
}

.sidebar-light .nav > .nav-item > .submenu {
  background-color: #fff;
}

.sidebar-light .submenu .nav-link {
  color: #757678;
  font-size: 0.9125rem;
}

.sidebar-light .submenu .nav-item.open > .nav-link {
  color: #2a7ab8;
}

.sidebar-light .submenu .nav-item > .nav-link:hover {
  color: #3576a9;
  background-color: #f0f5f9;
}

.sidebar-light .submenu .nav-icon {
  color: #99a0a8;
  font-size: 1.1875rem;
}

.sidebar-light .submenu .nav-link:hover > .nav-icon {
  color: #92afd0;
}

.sidebar-light .submenu .nav-item.open > .nav-link > .nav-icon {
  color: #92afd0;
}

.sidebar-light .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-light .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-light .open > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-light .nav-item > .nav-link:hover > .caret {
  opacity: 0.82;
}

.sidebar-light .nav-item.active > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-light .nav-item.active.open > .nav-link > .caret {
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-light.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-light .nav > .nav-item.active > .nav-link {
  color: #3c84c7;
  background-color: #fff;
}

.sidebar-light .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-light .nav > .nav-item.active > .nav-link > .nav-icon {
  color: #568ec2;
}

.sidebar-light .submenu .nav-item.active > .nav-link {
  color: #2a7ab8;
}

.sidebar-light .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-light .submenu .nav-item.active:not(.open) > .nav-link {
  background-color: #f0f5f9;
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-light.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-light.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #2a7ab8;
    background-color: #f0f5f9;
    font-weight: 600;
  }
}

@media (hover: hover) {
  .sidebar-light .nav > .nav-item > .nav-link:hover::before {
    border-color: #7eb0d7;
  }
}

.sidebar-light .nav.has-active-border > .nav-item.active::before {
  content: "";
  border-color: #7eb0d7;
}

.sidebar-light .nav.has-active-border .nav-item.active > .nav-link::before {
  display: none !important;
}

.sidebar-light .nav > .nav-item > .submenu::before {
  content: "";
  display: block;
  border-left: 1px dotted #bcced4;
  position: absolute;
  left: 2rem;
  top: 0.375rem;
  bottom: 0.375rem;
  z-index: 1;
}

.sidebar-light .nav-item .nav-item::before {
  content: "";
  display: block;
  width: 0.5rem;
  border-top: 1px dotted #bcced4;
  position: absolute;
  left: calc(2rem + 2px);
  top: 1.5rem;
  z-index: 1;
}

.sidebar-light .submenu .nav-link {
  padding-left: 3.375em;
}

.sidebar-light .submenu .submenu .nav-link {
  padding-left: 4.675em;
}

.sidebar-light .submenu .submenu .submenu .nav-link {
  padding-left: 5.325em;
}

.sidebar-light .nav-item-caption {
  color: #747981;
  font-size: 0.875rem;
}

.sidebar-light .nav-item-divider {
  border-top: 1px solid #dde3e6;
}

.sidebar-light .submenu .nav-item-divider {
  border-top-color: #eceff1;
}

.sidebar-light .nav > .nav-item > .submenu > .submenu-inner {
  border-top: 1px solid #dfe3e8;
}

@media (min-width: 1200px) {
  .sidebar-light.hoverable.collapsed .nav > .nav-item > .submenu > .submenu-inner {
    border-top-width: 0;
  }
}

.sidebar-light .ace-scroll {
  scrollbar-color: #9dbad5 #dce3ea;
}

.sidebar-light .ace-scroll::-webkit-scrollbar-track {
  background-color: #dce3ea;
  border-right: 1px solid #f5f5f6;
}

.sidebar-light .ace-scroll::-webkit-scrollbar-thumb {
  background-color: #9dbad5;
  border-right: 1px solid #f5f5f6;
}

@media (min-width: 1200px) {
  .sidebar-light.toggling .nav > .nav-item.active.open {
    transition: none;
  }
  .sidebar-light.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active), .sidebar-light.hoverable.collapsed .nav > .nav-item.open:not(.active) {
    border-color: transparent;
    background-color: transparent;
  }
  .sidebar-light.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link, .sidebar-light.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link {
    color: #55595d;
    background-color: transparent;
  }
  .sidebar-light.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon, .sidebar-light.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon {
    color: #7092b8;
  }
  .sidebar-light.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open, .sidebar-light.hoverable.collapsed .nav > .nav-item.active.open {
    transition: none;
  }
}

.sidebar-light .sidebar-search-input {
  color: rgba(68, 80, 92, 0.9);
  border-bottom-color: rgba(204, 204, 204, 0.4);
}

.sidebar-light .sidebar-search-input:focus {
  border-bottom-color: rgba(140, 174, 213, 0.73);
}

.sidebar-light .sidebar-search-input::placeholder {
  color: rgba(129, 131, 136, 0.5);
}

.sidebar-light .sidebar-search-input::-moz-placeholder {
  color: #818388;
}

.sidebar-white {
  background-color: #fff;
}

.sidebar-white .sidebar-inner {
  border-right: 1px solid #e2eaf1;
  background: inherit;
}

@media (min-width: 1200px) {
  .sidebar-white.sidebar-hover:not(.collapsed) .sidebar-inner {
    box-shadow: 1px 0 0 0 #e2eaf1;
    border-right-width: 0;
  }
}

@media (max-width: 1199.98px) {
  .sidebar-white:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #e2eaf1;
  }
}

.sidebar-white .nav > .nav-item {
  margin-bottom: 2px;
}

.sidebar-white .nav > .nav-item > .nav-link {
  color: #7b8b9c;
  font-size: 0.925rem;
  font-weight: 600;
}

.sidebar-white .nav > .nav-item > .nav-link > .nav-icon {
  color: #8f98a8;
  font-size: 1.25em;
}

.sidebar-white .nav > .nav-item.open > .nav-link {
  color: #3e86be;
  background-color: transparent;
}

.sidebar-white.sidebar-spaced .nav > .nav-item.open:not(.active) > .nav-link {
  background-color: #f0f5f9;
}

.sidebar-white .nav > .nav-item.open > .nav-link > .nav-icon {
  color: #5b99c9;
}

.sidebar-white .nav > .nav-item > .nav-link:hover {
  background-color: #f0f5f9;
  color: #3e86be;
}

.sidebar-white .nav > .nav-item > .nav-link:hover > .nav-icon {
  color: #5b99c9;
}

.sidebar-white .nav > .nav-item > .submenu {
  background-color: #fff;
}

.sidebar-white .submenu .nav-link {
  color: #617288;
  font-size: 0.9125rem;
}

.sidebar-white .submenu .nav-item.open > .nav-link {
  color: #3e7ab1;
}

.sidebar-white .submenu .nav-item > .nav-link:hover {
  color: #3e7ab1;
  background-color: #f0f5f9;
}

.sidebar-white .submenu .nav-icon {
  color: #99a0a8;
  font-size: 1.1875rem;
}

.sidebar-white .submenu .nav-link:hover > .nav-icon {
  color: #92afd0;
}

.sidebar-white .submenu .nav-item.open > .nav-link > .nav-icon {
  color: #92afd0;
}

.sidebar-white .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-white .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-white .open > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-white .nav-item > .nav-link:hover > .caret {
  opacity: 0.82;
}

.sidebar-white .nav-item.active > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-white .nav-item.active.open > .nav-link > .caret {
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-white.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-white .nav > .nav-item.active > .nav-link {
  color: #3c80b6;
  background-color: #ebf3f9;
}

.sidebar-white .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-white .nav > .nav-item.active.open > .nav-link:not(:hover) {
  background-color: transparent;
}

@media (min-width: 1200px) {
  .sidebar-white.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open > .nav-link {
    background-color: #ebf3f9;
  }
}

.sidebar-white .nav > .nav-item.active > .nav-link > .nav-icon {
  color: #407eb7;
}

.sidebar-white .submenu .nav-item.active > .nav-link {
  color: #4485b9;
}

.sidebar-white .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-white .submenu .nav-item.active:not(.open) > .nav-link {
  background-color: #ebf3f9;
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-white.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-white.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #4485b9;
    background-color: #ebf3f9;
    font-weight: 600;
  }
  .sidebar-white.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-white.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

@media (hover: hover) {
  .sidebar-white .nav > .nav-item > .nav-link:hover::before {
    border-color: #7eb0d7;
  }
}

.sidebar-white .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: #7eb0d7;
}

.sidebar-white .nav.has-active-border > .nav-item.active:hover > .nav-link::before {
  border-color: #7eb0d7;
}

@media (hover: hover) {
  .sidebar-white .nav.has-active-border > .nav-item.active.open > .nav-link:hover::before {
    display: block;
  }
}

.sidebar-white .submenu .nav-link {
  padding-left: 3.75em;
}

.sidebar-white .submenu .submenu .nav-link {
  padding-left: 4.675em;
}

.sidebar-white .submenu .submenu .submenu .nav-link {
  padding-left: 5.325em;
}

.sidebar-white .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-white .submenu .submenu .nav-text::before {
  left: 3.675em;
}

.sidebar-white .submenu .submenu .submenu .nav-text::before {
  left: 4.325em;
}

.sidebar-white .submenu .nav-item > .nav-link:hover > .nav-text::before {
  content: "";
  display: block;
  opacity: 0.8;
}

.sidebar-white .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-white.hoverable.collapsed .submenu .nav-text::before, .sidebar-white.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

.sidebar-white .nav-item-caption {
  color: #707c8a;
  font-size: 0.875rem;
}

.sidebar-white .nav-item-divider {
  border-top: 1px solid #dde3e6;
}

.sidebar-white .submenu .nav-item-divider {
  border-top-color: #eceff1;
}

.sidebar-white .ace-scroll {
  scrollbar-color: #9dbad5 #dce3ea;
}

.sidebar-white .ace-scroll::-webkit-scrollbar-track {
  background-color: #dce3ea;
  border-right: 1px solid #fff;
}

.sidebar-white .ace-scroll::-webkit-scrollbar-thumb {
  background-color: #9dbad5;
  border-right: 1px solid #fff;
}

.sidebar-white.sidebar-spaced .nav > .nav-item {
  border-radius: 0;
}

@media (min-width: 1200px) {
  .sidebar-white.sidebar-spaced.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item {
    border-radius: 0;
  }
}

@media (min-width: 1200px) {
  .sidebar-white.toggling .nav > .nav-item.active.open {
    transition: none;
  }
  .sidebar-white.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active), .sidebar-white.hoverable.collapsed .nav > .nav-item.open:not(.active) {
    border-color: transparent;
    background-color: transparent;
  }
  .sidebar-white.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link, .sidebar-white.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link {
    color: #7b8b9c;
    background-color: transparent;
  }
  .sidebar-white.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon, .sidebar-white.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon {
    color: #8f98a8;
  }
  .sidebar-white.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open, .sidebar-white.hoverable.collapsed .nav > .nav-item.active.open {
    transition: none;
  }
}

.sidebar-white .sidebar-search-input {
  color: rgba(68, 80, 92, 0.9);
  border-bottom-color: rgba(204, 204, 204, 0.4);
}

.sidebar-white .sidebar-search-input:focus {
  border-bottom-color: rgba(140, 174, 213, 0.73);
}

.sidebar-white .sidebar-search-input::placeholder {
  color: rgba(129, 131, 136, 0.5);
}

.sidebar-white .sidebar-search-input::-moz-placeholder {
  color: #818388;
}

.sidebar-white2 {
  background-color: #fff;
}

.sidebar-white2 .sidebar-inner {
  border-right: 1px solid #e2eaf1;
  background: inherit;
}

@media (min-width: 1200px) {
  .sidebar-white2.sidebar-hover:not(.collapsed) .sidebar-inner {
    box-shadow: 1px 0 0 0 #e2eaf1;
    border-right-width: 0;
  }
}

@media (max-width: 1199.98px) {
  .sidebar-white2:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #e2eaf1;
  }
}

.sidebar-white2 .nav > .nav-item {
  margin-bottom: 2px;
}

.sidebar-white2 .nav > .nav-item > .nav-link {
  color: #575c5f;
  font-size: 0.95rem;
}

.sidebar-white2 .nav > .nav-item > .nav-link > .nav-icon {
  color: #697d94;
  font-size: 1.25em;
}

.sidebar-white2 .nav > .nav-item.open > .nav-link {
  color: #3f4244;
  background-color: transparent;
}

.sidebar-white2.sidebar-spaced .nav > .nav-item.open:not(.active) > .nav-link {
  background-color: #f0f3f7;
}

.sidebar-white2 .nav > .nav-item.open > .nav-link > .nav-icon {
  color: #697d94;
}

.sidebar-white2 .nav > .nav-item > .nav-link:hover {
  background-color: #f0f3f7;
  color: #3f4244;
}

.sidebar-white2 .nav > .nav-item > .nav-link:hover > .nav-icon {
  color: #697d94;
}

.sidebar-white2 .nav > .nav-item > .submenu {
  background-color: #fff;
}

.sidebar-white2 .submenu .nav-link {
  color: #52575a;
  font-size: 0.9125rem;
}

.sidebar-white2 .submenu .nav-item.open > .nav-link {
  color: #3f4244;
}

.sidebar-white2 .submenu .nav-item > .nav-link:hover {
  color: #3f4244;
  background-color: #eff2f7;
}

.sidebar-white2 .submenu .nav-icon {
  color: #99a0a8;
  font-size: 1.1875rem;
}

.sidebar-white2 .submenu .nav-link:hover > .nav-icon {
  color: #92afd0;
}

.sidebar-white2 .submenu .nav-item.open > .nav-link > .nav-icon {
  color: #92afd0;
}

.sidebar-white2 .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-white2 .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-white2 .open > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-white2 .nav-item > .nav-link:hover > .caret {
  opacity: 0.82;
}

.sidebar-white2 .nav-item.active > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-white2 .nav-item.active.open > .nav-link > .caret {
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-white2.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-white2 .nav > .nav-item.active > .nav-link {
  color: #3c80b6;
  background-color: #ebf3f9;
  font-weight: 600;
}

.sidebar-white2 .nav > .nav-item.active > .nav-link > .nav-icon {
  color: #4986cc;
}

.sidebar-white2 .submenu .nav-item.active > .nav-link {
  color: #2b7dbc;
}

.sidebar-white2 .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-white2 .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-white2.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-white2.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #2b7dbc;
    font-weight: 600;
  }
  .sidebar-white2.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-white2.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-white2 .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: #7eb0d7;
}

.sidebar-white2 .submenu .nav-link {
  padding-left: 3.75em;
}

.sidebar-white2 .submenu .submenu .nav-link {
  padding-left: 4.675em;
}

.sidebar-white2 .submenu .submenu .submenu .nav-link {
  padding-left: 5.325em;
}

.sidebar-white2 .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-white2 .submenu .submenu .nav-text::before {
  left: 3.675em;
}

.sidebar-white2 .submenu .submenu .submenu .nav-text::before {
  left: 4.325em;
}

.sidebar-white2 .submenu .nav-item > .nav-link:hover > .nav-text::before {
  content: "";
  display: block;
  opacity: 0.8;
}

.sidebar-white2 .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-white2.hoverable.collapsed .submenu .nav-text::before, .sidebar-white2.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

.sidebar-white2 .nav-item-caption {
  color: #507bab;
  font-size: 0.875rem;
}

.sidebar-white2 .nav-item-divider {
  border-top: 1px solid #dde3e6;
}

.sidebar-white2 .submenu .nav-item-divider {
  border-top-color: #eceff1;
}

.sidebar-white2 .ace-scroll {
  scrollbar-color: #9dbad5 #dce3ea;
}

.sidebar-white2 .ace-scroll::-webkit-scrollbar-track {
  background-color: #dce3ea;
  border-right: 1px solid #fff;
}

.sidebar-white2 .ace-scroll::-webkit-scrollbar-thumb {
  background-color: #9dbad5;
  border-right: 1px solid #fff;
}

.sidebar-white2.sidebar-spaced .nav > .nav-item {
  border-radius: 0;
}

@media (min-width: 1200px) {
  .sidebar-white2.sidebar-spaced.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item {
    border-radius: 0;
  }
}

@media (min-width: 1200px) {
  .sidebar-white2.toggling .nav > .nav-item.active.open {
    transition: none;
  }
  .sidebar-white2.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active), .sidebar-white2.hoverable.collapsed .nav > .nav-item.open:not(.active) {
    border-color: transparent;
    background-color: transparent;
  }
  .sidebar-white2.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link, .sidebar-white2.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link {
    color: #575c5f;
    background-color: transparent;
  }
  .sidebar-white2.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon, .sidebar-white2.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon {
    color: #697d94;
  }
  .sidebar-white2.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open, .sidebar-white2.hoverable.collapsed .nav > .nav-item.active.open {
    transition: none;
  }
}

.sidebar-white2 .sidebar-search-input {
  color: rgba(68, 80, 92, 0.9);
  border-bottom-color: rgba(204, 204, 204, 0.4);
}

.sidebar-white2 .sidebar-search-input:focus {
  border-bottom-color: rgba(140, 174, 213, 0.73);
}

.sidebar-white2 .sidebar-search-input::placeholder {
  color: rgba(129, 131, 136, 0.5);
}

.sidebar-white2 .sidebar-search-input::-moz-placeholder {
  color: #818388;
}

.sidebar-white3 {
  background-color: #fff;
}

.sidebar-white3 .sidebar-inner {
  border-right: 1px solid #e2eaf1;
  background: inherit;
}

@media (min-width: 1200px) {
  .sidebar-white3.sidebar-hover:not(.collapsed) .sidebar-inner {
    box-shadow: 1px 0 0 0 #e2eaf1;
    border-right-width: 0;
  }
}

@media (max-width: 1199.98px) {
  .sidebar-white3:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #e2eaf1;
  }
}

.sidebar-white3 .nav > .nav-item::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -1px;
  left: 1.25rem;
  right: 1.25rem;
  height: 0;
  width: auto;
  border: 0px solid #e9ebec;
  border-width: 0 0 1px 0;
}

@media (min-width: 1200px) {
  .sidebar-white3.sidebar-h .nav > .nav-item::after {
    display: none;
    /**
                border-width: 0 $item-border-size 0 0;
                $margin: map-get($sidebar, 'item-border-margin');
                @if $margin == null { $margin: map-get($sidebar-common, 'item-border-margin'); }
                left: auto;
                right: -2px;

                top: $margin * 0.75;
                bottom: $margin * 0.75;

                height: auto;
                width: 0;
                */
  }
}

.sidebar-white3 .nav > .nav-item {
  margin-bottom: 2px;
}

.sidebar-white3 .nav > .nav-item > .nav-link {
  color: #4f5052;
  font-size: 0.925rem;
}

.sidebar-white3 .nav > .nav-item > .nav-link > .nav-icon {
  color: #888997;
  font-size: 1.25em;
}

.sidebar-white3 .nav > .nav-item.open > .nav-link {
  color: #356da2;
  background-color: transparent;
}

.sidebar-white3 .nav > .nav-item.open > .nav-link > .nav-icon {
  color: #6a6c7d;
}

.sidebar-white3 .nav > .nav-item > .nav-link:hover {
  font-weight: 600;
  color: #3b7ab5;
}

.sidebar-white3 .nav > .nav-item > .nav-link:hover > .nav-icon {
  color: #888997;
}

.sidebar-white3 .nav > .nav-item > .submenu {
  background-color: #fff;
}

.sidebar-white3 .submenu .nav-link {
  color: #4f5052;
  font-size: 0.9125rem;
}

.sidebar-white3 .submenu .nav-item.open > .nav-link {
  color: #356da2;
}

.sidebar-white3 .submenu .nav-item > .nav-link:hover {
  color: #3b7ab5;
  font-weight: 600;
}

.sidebar-white3 .submenu .nav-icon {
  color: #99a0a8;
  font-size: 1.1875rem;
}

.sidebar-white3 .submenu .nav-link:hover > .nav-icon {
  color: #92afd0;
}

.sidebar-white3 .submenu .nav-item.open > .nav-link > .nav-icon {
  color: #92afd0;
}

.sidebar-white3 .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-white3 .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-white3 .open > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-white3 .nav-item > .nav-link:hover > .caret {
  opacity: 0.82;
}

.sidebar-white3 .nav-item.active > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-white3 .nav-item.active.open > .nav-link > .caret {
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-white3.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-white3 .nav > .nav-item.active > .nav-link {
  color: #3b7ab5;
  font-weight: 600;
}

.sidebar-white3 .nav > .nav-item.active.open > .nav-link:not(:hover) {
  background-color: transparent;
}

.sidebar-white3 .nav > .nav-item.active > .nav-link > .nav-icon {
  color: #407eb7;
}

.sidebar-white3 .submenu .nav-item.active > .nav-link {
  color: #4485b9;
  font-weight: 600;
}

.sidebar-white3 .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

@media (min-width: 1200px) {
  .sidebar-white3.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-white3.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #4485b9;
    font-weight: 600;
  }
}

.sidebar-white3 .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: #7eb0d7;
}

.sidebar-white3 .submenu .nav-link {
  padding-left: 3.75em;
}

.sidebar-white3 .submenu .submenu .nav-link {
  padding-left: 4.675em;
}

.sidebar-white3 .submenu .submenu .submenu .nav-link {
  padding-left: 5.325em;
}

.sidebar-white3 .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-white3 .submenu .submenu .nav-text::before {
  left: 3.675em;
}

.sidebar-white3 .submenu .submenu .submenu .nav-text::before {
  left: 4.325em;
}

.sidebar-white3 .submenu .nav-item > .nav-link:hover > .nav-text::before {
  content: "";
  display: block;
  opacity: 0.8;
}

@media (min-width: 1200px) {
  .sidebar-white3.hoverable.collapsed .submenu .nav-text::before, .sidebar-white3.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

.sidebar-white3 .nav-item-caption {
  color: #747577;
  font-size: 0.875rem;
}

.sidebar-white3 .nav-item-divider {
  border-top: 1px solid #dde3e6;
}

.sidebar-white3 .submenu .nav-item-divider {
  border-top-color: #eceff1;
}

.sidebar-white3 .ace-scroll {
  scrollbar-color: #9dbad5 #dce3ea;
}

.sidebar-white3 .ace-scroll::-webkit-scrollbar-track {
  background-color: #dce3ea;
  border-right: 1px solid #fff;
}

.sidebar-white3 .ace-scroll::-webkit-scrollbar-thumb {
  background-color: #9dbad5;
  border-right: 1px solid #fff;
}

.sidebar-white3.sidebar-spaced .nav > .nav-item {
  border-radius: 0;
}

@media (min-width: 1200px) {
  .sidebar-white3.sidebar-spaced.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item {
    border-radius: 0;
  }
}

@media (min-width: 1200px) {
  .sidebar-white3.toggling .nav > .nav-item.active.open {
    transition: none;
  }
  .sidebar-white3.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active), .sidebar-white3.hoverable.collapsed .nav > .nav-item.open:not(.active) {
    border-color: transparent;
    background-color: transparent;
  }
  .sidebar-white3.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link, .sidebar-white3.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link {
    color: #4f5052;
    background-color: transparent;
  }
  .sidebar-white3.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon, .sidebar-white3.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon {
    color: #888997;
  }
  .sidebar-white3.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open, .sidebar-white3.hoverable.collapsed .nav > .nav-item.active.open {
    transition: none;
  }
}

.sidebar-white3 .sidebar-search-input {
  color: rgba(68, 80, 92, 0.9);
  border-bottom-color: rgba(204, 204, 204, 0.4);
}

.sidebar-white3 .sidebar-search-input:focus {
  border-bottom-color: rgba(140, 174, 213, 0.73);
}

.sidebar-white3 .sidebar-search-input::placeholder {
  color: rgba(129, 131, 136, 0.5);
}

.sidebar-white3 .sidebar-search-input::-moz-placeholder {
  color: #818388;
}

.sidebar-lightpurple {
  background-color: #f7f7fa;
}

.sidebar-lightpurple .sidebar-inner {
  background: inherit;
  box-shadow: inset -1px 0 0 0px #dcd2ed;
}

.sidebar-lightpurple.toggling.has-open:not(.collapsed) .sidebar-inner {
  border-right: 1px solid #dcd2ed;
}

@media (max-width: 1199.98px) {
  .sidebar-lightpurple:not(.sidebar-push) .sidebar-inner {
    box-shadow: none;
    border-right: 1px solid #d1c3e7;
  }
  .sidebar-lightpurple:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #d1c3e7;
  }
}

.sidebar-lightpurple .nav > .nav-item {
  margin-bottom: 2px;
}

.sidebar-lightpurple .nav > .nav-item > .nav-link {
  color: #625e6f;
  font-size: 0.975rem;
  font-weight: 400;
}

.sidebar-lightpurple .nav > .nav-item > .nav-link > .nav-icon {
  color: #9188b3;
  font-size: 1.25em;
}

.sidebar-lightpurple .nav > .nav-item.open {
  background-color: #fff;
}

.sidebar-lightpurple.sidebar-spaced .nav > .nav-item.open:not(.active) > .nav-link {
  background-color: #fff;
}

.sidebar-lightpurple .nav > .nav-item > .nav-link:hover {
  background-color: #fff;
}

.sidebar-lightpurple .nav > .nav-item > .submenu {
  background-color: #fff;
}

.sidebar-lightpurple .submenu .nav-link {
  color: #736d85;
  font-size: 0.9125rem;
}

.sidebar-lightpurple .submenu .nav-item.open > .nav-link {
  color: #7d6fb9;
}

.sidebar-lightpurple .submenu .nav-item > .nav-link:hover {
  color: #7d6fb9;
  background-color: #f3f3fb;
}

.sidebar-lightpurple .submenu .nav-icon {
  color: #99a0a8;
  font-size: 1.1875rem;
}

.sidebar-lightpurple .submenu .nav-link:hover > .nav-icon {
  color: #92afd0;
}

.sidebar-lightpurple .submenu .nav-item.open > .nav-link > .nav-icon {
  color: #92afd0;
}

.sidebar-lightpurple .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-lightpurple .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-lightpurple .open > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-lightpurple .nav-item > .nav-link:hover > .caret {
  opacity: 0.82;
}

.sidebar-lightpurple .nav-item.active > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-lightpurple .nav-item.active.open > .nav-link > .caret {
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-lightpurple.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-lightpurple .nav > .nav-item.active > .nav-link {
  color: #8679be;
  background-color: #fff;
  font-weight: 600;
}

.sidebar-lightpurple .nav > .nav-item.active.open > .nav-link:not(:hover) {
  background-color: transparent;
}

@media (min-width: 1200px) {
  .sidebar-lightpurple.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open > .nav-link {
    background-color: #fff;
  }
}

.sidebar-lightpurple .nav > .nav-item.active > .nav-link > .nav-icon {
  color: #8e82c2;
}

.sidebar-lightpurple .submenu .nav-item.active > .nav-link {
  color: #7d6fb9;
  font-weight: 600;
}

.sidebar-lightpurple .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-lightpurple .submenu .nav-item.active:not(.open) > .nav-link {
  background-color: #f3f3fb;
}

@media (min-width: 1200px) {
  .sidebar-lightpurple.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-lightpurple.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #7d6fb9;
    background-color: #f3f3fb;
    font-weight: 600;
  }
  .sidebar-lightpurple.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-lightpurple.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

@media (min-width: 1200px) {
  .sidebar-lightpurple.expandable.collapsed .sidebar-inner:not(.is-hover) {
    box-shadow: inset -1px 0 0 0px #d2cee5;
  }
}

.sidebar-lightpurple .submenu .nav-link {
  padding-left: 3.75em;
}

.sidebar-lightpurple .submenu .submenu .nav-link {
  padding-left: 4.675em;
}

.sidebar-lightpurple .submenu .submenu .submenu .nav-link {
  padding-left: 5.325em;
}

.sidebar-lightpurple .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.375rem);
  width: 0;
  height: 0;
  border: 0.375rem solid transparent;
  border-left-color: currentColor;
  border-right-width: 0;
}

.sidebar-lightpurple .submenu .submenu .nav-text::before {
  left: 3.675em;
}

.sidebar-lightpurple .submenu .submenu .submenu .nav-text::before {
  left: 4.325em;
}

.sidebar-lightpurple .submenu .nav-item > .nav-link:hover > .nav-text::before {
  content: "";
  display: block;
  opacity: 0.8;
}

.sidebar-lightpurple .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-lightpurple.hoverable.collapsed .submenu .nav-text::before, .sidebar-lightpurple.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

.sidebar-lightpurple .nav-item-caption {
  color: #7670a3;
  font-size: 0.875rem;
}

.sidebar-lightpurple .nav-item-divider {
  border-top: 1px solid #dde3e6;
}

.sidebar-lightpurple .submenu .nav-item-divider {
  border-top-color: #eceff1;
}

.sidebar-lightpurple .ace-scroll {
  scrollbar-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.075);
}

.sidebar-lightpurple .ace-scroll::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.075);
  border-right: 1px solid #f7f7fa;
}

.sidebar-lightpurple .ace-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.15);
  border-right: 1px solid #f7f7fa;
}

.sidebar-lightpurple.sidebar-spaced .nav > .nav-item {
  border-radius: 0.5rem;
  border-style: solid;
  border-width: 2px;
  transition: border 0.15s;
  border-color: transparent;
}

.sidebar-lightpurple.sidebar-spaced .nav > .nav-item.open {
  border-color: #cfcae4 #d9d5e9;
}

.sidebar-lightpurple.sidebar-spaced .nav > .nav-item:hover {
  border-color: #cfcae4 #d9d5e9;
}

.sidebar-lightpurple.sidebar-spaced .nav > .nav-item.active {
  border-width: 2px;
  border-color: #bfb9db;
}

@media (min-width: 1200px) {
  .sidebar-lightpurple.sidebar-spaced.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item {
    border-radius: 0.5rem;
    border-width: 2px;
  }
}

@media (min-width: 1200px) {
  .sidebar-lightpurple.toggling .nav > .nav-item.active.open {
    transition: none;
  }
  .sidebar-lightpurple.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active), .sidebar-lightpurple.hoverable.collapsed .nav > .nav-item.open:not(.active) {
    border-color: transparent;
    background-color: transparent;
  }
  .sidebar-lightpurple.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link, .sidebar-lightpurple.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link {
    color: #625e6f;
    background-color: transparent;
  }
  .sidebar-lightpurple.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon, .sidebar-lightpurple.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon {
    color: #9188b3;
  }
  .sidebar-lightpurple.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open, .sidebar-lightpurple.hoverable.collapsed .nav > .nav-item.active.open {
    transition: none;
  }
}

.sidebar-lightpurple .sidebar-search-input {
  color: rgba(68, 80, 92, 0.9);
  border-bottom-color: rgba(204, 204, 204, 0.4);
}

.sidebar-lightpurple .sidebar-search-input:focus {
  border-bottom-color: rgba(140, 174, 213, 0.73);
}

.sidebar-lightpurple .sidebar-search-input::placeholder {
  color: rgba(129, 131, 136, 0.5);
}

.sidebar-lightpurple .sidebar-search-input::-moz-placeholder {
  color: #818388;
}

.sidebar-lightblue {
  background-color: #f3f8fb;
}

.sidebar-lightblue .sidebar-inner {
  background: inherit;
  box-shadow: inset -1px 0 0 0px #d8e6f3;
}

.sidebar-lightblue.toggling.has-open:not(.collapsed) .sidebar-inner {
  border-right: 1px solid #d8e6f3;
}

@media (max-width: 1199.98px) {
  .sidebar-lightblue:not(.sidebar-push) .sidebar-inner {
    box-shadow: none;
    border-right: 1px solid #c8dcee;
  }
  .sidebar-lightblue:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #c8dcee;
  }
}

.sidebar-lightblue .nav > .nav-item {
  margin-bottom: 2px;
}

.sidebar-lightblue .nav > .nav-item > .nav-link {
  color: #535556;
  font-size: 0.975rem;
  font-weight: 400;
}

.sidebar-lightblue .nav > .nav-item > .nav-link > .nav-icon {
  color: #6e7071;
  font-size: 1.25em;
}

.sidebar-lightblue .nav > .nav-item.open {
  background-color: #fff;
}

.sidebar-lightblue.sidebar-spaced .nav > .nav-item.open:not(.active) > .nav-link {
  background-color: #fff;
}

.sidebar-lightblue .nav > .nav-item.open > .nav-link > .nav-icon {
  color: #5b99c9;
}

.sidebar-lightblue .nav > .nav-item > .nav-link:hover {
  background-color: #fff;
}

.sidebar-lightblue .nav > .nav-item > .submenu {
  background-color: #fff;
}

.sidebar-lightblue .submenu .nav-link {
  color: #5e6061;
  font-size: 0.9125rem;
}

.sidebar-lightblue .submenu .nav-item.open > .nav-link {
  color: #3e7ab1;
}

.sidebar-lightblue .submenu .nav-item > .nav-link:hover {
  color: #3e7ab1;
  background-color: #f0f5f9;
}

.sidebar-lightblue .submenu .nav-icon {
  color: #99a0a8;
  font-size: 1.1875rem;
}

.sidebar-lightblue .submenu .nav-link:hover > .nav-icon {
  color: #92afd0;
}

.sidebar-lightblue .submenu .nav-item.open > .nav-link > .nav-icon {
  color: #92afd0;
}

.sidebar-lightblue .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-lightblue .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-lightblue .open > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-lightblue .nav-item > .nav-link:hover > .caret {
  opacity: 0.82;
}

.sidebar-lightblue .nav-item.active > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-lightblue .nav-item.active.open > .nav-link > .caret {
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-lightblue.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-lightblue .nav > .nav-item.active > .nav-link {
  color: #488cc4;
  background-color: #fff;
  font-weight: 600;
}

.sidebar-lightblue .nav > .nav-item.active.open > .nav-link:not(:hover) {
  background-color: transparent;
}

@media (min-width: 1200px) {
  .sidebar-lightblue.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open > .nav-link {
    background-color: #fff;
  }
}

.sidebar-lightblue .nav > .nav-item.active > .nav-link > .nav-icon {
  color: #609bcc;
}

.sidebar-lightblue .submenu .nav-item.active > .nav-link {
  color: #4485b9;
}

.sidebar-lightblue .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-lightblue .submenu .nav-item.active:not(.open) > .nav-link {
  background-color: #ebf3f9;
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-lightblue.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-lightblue.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #4485b9;
    background-color: #ebf3f9;
    font-weight: 600;
  }
  .sidebar-lightblue.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-lightblue.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

@media (min-width: 1200px) {
  .sidebar-lightblue.expandable.collapsed .sidebar-inner:not(.is-hover) {
    box-shadow: inset -1px 0 0 0px #c5d9e8;
  }
}

.sidebar-lightblue .submenu .nav-link {
  padding-left: 3.75em;
}

.sidebar-lightblue .submenu .submenu .nav-link {
  padding-left: 4.675em;
}

.sidebar-lightblue .submenu .submenu .submenu .nav-link {
  padding-left: 5.325em;
}

.sidebar-lightblue .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-lightblue .submenu .submenu .nav-text::before {
  left: 3.675em;
}

.sidebar-lightblue .submenu .submenu .submenu .nav-text::before {
  left: 4.325em;
}

.sidebar-lightblue .submenu .nav-item > .nav-link:hover > .nav-text::before {
  content: "";
  display: block;
  opacity: 0.8;
}

.sidebar-lightblue .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-lightblue.hoverable.collapsed .submenu .nav-text::before, .sidebar-lightblue.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

.sidebar-lightblue .nav-item-caption {
  color: #687278;
  font-size: 0.875rem;
}

.sidebar-lightblue .nav-item-divider {
  border-top: 1px solid #dde3e6;
}

.sidebar-lightblue .submenu .nav-item-divider {
  border-top-color: #eceff1;
}

.sidebar-lightblue .ace-scroll {
  scrollbar-color: #9dbad5 #dce3ea;
}

.sidebar-lightblue .ace-scroll::-webkit-scrollbar-track {
  background-color: #dce3ea;
  border-right: 1px solid #f3f8fb;
}

.sidebar-lightblue .ace-scroll::-webkit-scrollbar-thumb {
  background-color: #9dbad5;
  border-right: 1px solid #f3f8fb;
}

.sidebar-lightblue.sidebar-spaced .nav > .nav-item {
  border-radius: 1.75rem;
  border-style: solid;
  border-width: 2px;
  transition: border 0.15s;
  border-color: transparent;
}

.sidebar-lightblue.sidebar-spaced .nav > .nav-item.open {
  border-radius: 1.25rem;
  border-width: 2px 1px;
  border-color: #b5cee2;
}

.sidebar-lightblue.sidebar-spaced .nav > .nav-item:hover {
  border-color: #b5cee2;
}

.sidebar-lightblue.sidebar-spaced .nav > .nav-item.active {
  border-width: 2px;
  border-color: #aec9df;
}

.sidebar-lightblue.sidebar-spaced .nav > .nav-item.active.open {
  border-width: 2px 1px;
  border-color: #b2d0e7 #bed7eb;
}

@media (min-width: 1200px) {
  .sidebar-lightblue.sidebar-spaced.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item {
    border-radius: 1.75rem;
    border-width: 2px;
  }
}

@media (min-width: 1200px) {
  .sidebar-lightblue.toggling .nav > .nav-item.active.open {
    transition: none;
  }
  .sidebar-lightblue.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active), .sidebar-lightblue.hoverable.collapsed .nav > .nav-item.open:not(.active) {
    border-color: transparent;
    background-color: transparent;
  }
  .sidebar-lightblue.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link, .sidebar-lightblue.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link {
    color: #535556;
    background-color: transparent;
  }
  .sidebar-lightblue.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon, .sidebar-lightblue.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon {
    color: #6e7071;
  }
  .sidebar-lightblue.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open, .sidebar-lightblue.hoverable.collapsed .nav > .nav-item.active.open {
    transition: none;
  }
}

.sidebar-lightblue .sidebar-search-input {
  color: rgba(68, 80, 92, 0.9);
  border-bottom-color: rgba(204, 204, 204, 0.4);
}

.sidebar-lightblue .sidebar-search-input:focus {
  border-bottom-color: rgba(140, 174, 213, 0.73);
}

.sidebar-lightblue .sidebar-search-input::placeholder {
  color: rgba(129, 131, 136, 0.5);
}

.sidebar-lightblue .sidebar-search-input::-moz-placeholder {
  color: #818388;
}

.sidebar-lightblue2 {
  background-color: #f6f8fc;
}

.sidebar-lightblue2 .sidebar-inner {
  background: inherit;
  box-shadow: inset -1px 0 0 0px #d8e6f3;
}

.sidebar-lightblue2.toggling.has-open:not(.collapsed) .sidebar-inner {
  border-right: 1px solid #d8e6f3;
}

@media (max-width: 1199.98px) {
  .sidebar-lightblue2:not(.sidebar-push) .sidebar-inner {
    box-shadow: none;
    border-right: 1px solid #c8dcee;
  }
  .sidebar-lightblue2:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #c8dcee;
  }
}

.sidebar-lightblue2 .nav > .nav-item {
  margin-bottom: 2px;
}

.sidebar-lightblue2 .nav > .nav-item > .nav-link {
  color: #58636f;
  font-size: 0.95rem;
  font-weight: 400;
}

.sidebar-lightblue2 .nav > .nav-item > .nav-link > .nav-icon {
  color: #73a1bb;
  font-size: 1.25em;
}

.sidebar-lightblue2 .nav > .nav-item.open > .nav-link {
  background-color: #fff;
}

.sidebar-lightblue2 .nav > .nav-item > .nav-link:hover {
  background-color: #fff;
}

.sidebar-lightblue2 .nav > .nav-item > .submenu {
  background-color: #fff;
}

.sidebar-lightblue2 .submenu .nav-link {
  color: #617288;
  font-size: 0.9125rem;
}

.sidebar-lightblue2 .submenu .nav-item.open > .nav-link {
  color: #3e7ab1;
}

.sidebar-lightblue2 .submenu .nav-item > .nav-link:hover {
  color: #3e7ab1;
  background-color: #f0f5f9;
}

.sidebar-lightblue2 .submenu .nav-icon {
  color: #99a0a8;
  font-size: 1.1875rem;
}

.sidebar-lightblue2 .submenu .nav-link:hover > .nav-icon {
  color: #92afd0;
}

.sidebar-lightblue2 .submenu .nav-item.open > .nav-link > .nav-icon {
  color: #92afd0;
}

.sidebar-lightblue2 .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-lightblue2 .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-lightblue2 .open > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-lightblue2 .nav-item > .nav-link:hover > .caret {
  opacity: 0.82;
}

.sidebar-lightblue2 .nav-item.active > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-lightblue2 .nav-item.active.open > .nav-link > .caret {
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-lightblue2.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-lightblue2 .nav > .nav-item.active > .nav-link {
  color: #fff;
  background-color: #65aadb;
  font-weight: 600;
}

.sidebar-lightblue2 .nav > .nav-item.active > .nav-link > .nav-icon {
  color: #fff;
}

.sidebar-lightblue2 .submenu .nav-item.active > .nav-link {
  color: #4485b9;
}

.sidebar-lightblue2 .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-lightblue2 .submenu .nav-item.active:not(.open) > .nav-link {
  background-color: #ebf3f9;
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-lightblue2.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-lightblue2.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #4485b9;
    background-color: #ebf3f9;
    font-weight: 600;
  }
  .sidebar-lightblue2.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-lightblue2.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-lightblue2 .submenu .nav-link {
  padding-left: 3.25em;
}

.sidebar-lightblue2 .submenu .submenu .nav-link {
  padding-left: 4.5em;
}

.sidebar-lightblue2 .submenu .submenu .submenu .nav-link {
  padding-left: 5.25em;
}

.sidebar-lightblue2 .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.25em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-lightblue2 .submenu .submenu .nav-text::before {
  left: 3.5em;
}

.sidebar-lightblue2 .submenu .submenu .submenu .nav-text::before {
  left: 4.25em;
}

.sidebar-lightblue2 .submenu .nav-item > .nav-link:hover > .nav-text::before {
  content: "";
  display: block;
  opacity: 0.8;
}

.sidebar-lightblue2 .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-lightblue2.hoverable.collapsed .submenu .nav-text::before, .sidebar-lightblue2.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

.sidebar-lightblue2 .nav-item-caption {
  color: #70808c;
  font-size: 0.875rem;
}

.sidebar-lightblue2 .nav-item-divider {
  border-top: 1px solid #dde3e6;
}

.sidebar-lightblue2 .submenu .nav-item-divider {
  border-top-color: #eceff1;
}

.sidebar-lightblue2 .ace-scroll {
  scrollbar-color: #9dbad5 #dce3ea;
}

.sidebar-lightblue2 .ace-scroll::-webkit-scrollbar-track {
  background-color: #dce3ea;
  border-right: 1px solid #f6f8fc;
}

.sidebar-lightblue2 .ace-scroll::-webkit-scrollbar-thumb {
  background-color: #9dbad5;
  border-right: 1px solid #f6f8fc;
}

.sidebar-lightblue2.sidebar-spaced .nav > .nav-item {
  border-radius: 0.5rem;
  border-style: solid;
  border-width: 2px;
  transition: border 0.15s;
  border-color: transparent;
}

.sidebar-lightblue2.sidebar-spaced .nav > .nav-item.open {
  border-color: #b2cce2;
}

.sidebar-lightblue2.sidebar-spaced .nav > .nav-item:hover {
  border-color: #b2cce2;
}

.sidebar-lightblue2.sidebar-spaced .nav > .nav-item.active {
  border-color: #65aadb;
}

@media (min-width: 1200px) {
  .sidebar-lightblue2.sidebar-spaced.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item {
    border-radius: 0.5rem;
    border-width: 2px;
  }
}

@media (min-width: 1200px) {
  .sidebar-lightblue2.toggling .nav > .nav-item.active.open {
    transition: none;
  }
  .sidebar-lightblue2.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active), .sidebar-lightblue2.hoverable.collapsed .nav > .nav-item.open:not(.active) {
    border-color: transparent;
    background-color: transparent;
  }
  .sidebar-lightblue2.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link, .sidebar-lightblue2.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link {
    color: #58636f;
    background-color: transparent;
  }
  .sidebar-lightblue2.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon, .sidebar-lightblue2.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon {
    color: #73a1bb;
  }
  .sidebar-lightblue2.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open, .sidebar-lightblue2.hoverable.collapsed .nav > .nav-item.active.open {
    transition: none;
  }
}

.sidebar-lightblue2 .sidebar-search-input {
  color: rgba(68, 80, 92, 0.9);
  border-bottom-color: rgba(204, 204, 204, 0.4);
}

.sidebar-lightblue2 .sidebar-search-input:focus {
  border-bottom-color: rgba(140, 174, 213, 0.73);
}

.sidebar-lightblue2 .sidebar-search-input::placeholder {
  color: rgba(129, 131, 136, 0.5);
}

.sidebar-lightblue2 .sidebar-search-input::-moz-placeholder {
  color: #818388;
}

.sidebar-color {
  background-color: #33414a;
}

.sidebar-color .sidebar-inner {
  background: inherit;
}

@media (max-width: 1199.98px) {
  .sidebar-color:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #33414a;
  }
}

.sidebar-color .nav > .nav-item > .nav-link {
  color: rgba(255, 255, 255, 0.92);
  font-size: 0.9625rem;
}

.sidebar-color .nav > .nav-item > .nav-link > .nav-icon {
  color: rgba(255, 255, 255, 0.78);
  font-size: 1.25em;
}

.sidebar-color .nav > .nav-item.open > .nav-link {
  color: rgba(255, 255, 255, 0.92);
  background-color: transparent;
}

.sidebar-color.sidebar-spaced .nav > .nav-item.open:not(.active) > .nav-link {
  background-color: rgba(255, 255, 255, 0.125);
}

.sidebar-color .nav > .nav-item > .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.125);
  color: #fff;
}

.sidebar-color .nav > .nav-item > .nav-link:hover > .nav-icon {
  color: rgba(255, 255, 238, 0.9);
}

.sidebar-color .nav > .nav-item > .submenu {
  background-color: rgba(0, 0, 0, 0.2);
}

.sidebar-color .submenu .nav-link {
  color: rgba(255, 255, 255, 0.85);
  font-size: 0.9125rem;
}

.sidebar-color .submenu .nav-item.open > .nav-link {
  color: rgba(255, 255, 255, 0.95);
}

.sidebar-color .submenu .nav-item > .nav-link:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.175);
}

.sidebar-color .submenu .nav-icon {
  color: #92afd0;
  font-size: 1.1875rem;
}

.sidebar-color .submenu .nav-link:hover > .nav-icon {
  color: #92afd0;
}

.sidebar-color .submenu .nav-item.open > .nav-link > .nav-icon {
  color: #92afd0;
}

.sidebar-color .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-color .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-color .open > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-color .nav-item > .nav-link:hover > .caret {
  opacity: 0.82;
}

.sidebar-color .nav-item.active > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-color .nav-item.active.open > .nav-link > .caret {
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-color.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-color .nav > .nav-item.active > .nav-link {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.sidebar-color .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-color .nav > .nav-item.active > .nav-link > .nav-icon {
  color: rgba(255, 255, 255, 0.95);
}

.sidebar-color .submenu .nav-item.active > .nav-link {
  color: #8ebfe7;
}

.sidebar-color .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-color .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-color.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-color.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #8ebfe7;
    font-weight: 600;
  }
}

.sidebar-color .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: rgba(255, 255, 255, 0.75);
}

.sidebar-color .submenu .nav-link {
  padding-left: 3.75em;
}

.sidebar-color .submenu .submenu .nav-link {
  padding-left: 4.675em;
}

.sidebar-color .submenu .submenu .submenu .nav-link {
  padding-left: 5.325em;
}

.sidebar-color .nav-item-caption {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
}

.sidebar-color .nav-item-divider {
  border-top: 1px solid rgba(208, 217, 221, 0.175);
}

.sidebar-color .submenu .nav-item-divider {
  border-top-color: rgba(223, 229, 232, 0.175);
}

@media (min-width: 1200px) {
  .sidebar-color.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-color.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-color.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #33414a;
  }
  .sidebar-color.hoverable.collapsed .sub-arrow {
    border-color: #33414a !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-color.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-color.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-color.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-color.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #33414a;
  }
  .sidebar-color.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-color.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #33414a !important;
  }
}

.sidebar-color .ace-scroll {
  scrollbar-color: rgba(255, 255, 255, 0.75) rgba(255, 255, 255, 0.33);
}

.sidebar-color .ace-scroll::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.33);
  border-right: 1px solid #33414a;
}

.sidebar-color .ace-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.75);
  border-right: 1px solid #33414a;
}

@media (min-width: 1200px) {
  .sidebar-color.toggling .nav > .nav-item.active.open {
    transition: none;
  }
  .sidebar-color.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active), .sidebar-color.hoverable.collapsed .nav > .nav-item.open:not(.active) {
    border-color: transparent;
    background-color: transparent;
  }
  .sidebar-color.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link, .sidebar-color.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link {
    color: rgba(255, 255, 255, 0.92);
    background-color: transparent;
  }
  .sidebar-color.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon, .sidebar-color.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon {
    color: rgba(255, 255, 255, 0.78);
  }
  .sidebar-color.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open, .sidebar-color.hoverable.collapsed .nav > .nav-item.active.open {
    transition: none;
  }
}

.sidebar-color .sidebar-search-input {
  color: rgba(255, 255, 255, 0.85);
  border-bottom-color: rgba(255, 255, 255, 0.15);
}

.sidebar-color .sidebar-search-input:focus {
  border-bottom-color: rgba(255, 255, 255, 0.5);
}

.sidebar-color .sidebar-search-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-color .sidebar-search-input::-moz-placeholder {
  color: #fff;
}

.sidebar-dark {
  background-color: #33414a;
}

.sidebar-dark .sidebar-inner {
  background: inherit;
}

@media (max-width: 1199.98px) {
  .sidebar-dark:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #33414a;
  }
}

.sidebar-dark .nav > .nav-item::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -1px;
  left: 1rem;
  right: 1rem;
  height: 0;
  width: auto;
  border: 0px solid rgba(0, 0, 0, 0.13);
  border-width: 0 0 1px 0;
}

@media (min-width: 1200px) {
  .sidebar-dark.sidebar-h .nav > .nav-item::after {
    display: none;
    /**
                border-width: 0 $item-border-size 0 0;
                $margin: map-get($sidebar, 'item-border-margin');
                @if $margin == null { $margin: map-get($sidebar-common, 'item-border-margin'); }
                left: auto;
                right: -2px;

                top: $margin * 0.75;
                bottom: $margin * 0.75;

                height: auto;
                width: 0;
                */
  }
}

.sidebar-dark .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-dark .nav > .nav-item > .nav-link {
  color: rgba(255, 255, 255, 0.88);
  font-size: 0.9625rem;
}

.sidebar-dark .nav > .nav-item > .nav-link > .nav-icon {
  color: rgba(187, 218, 238, 0.85);
  font-size: 1.25em;
}

.sidebar-dark .nav > .nav-item.open > .nav-link {
  color: rgba(255, 255, 255, 0.92);
  background-color: transparent;
}

.sidebar-dark.sidebar-spaced .nav > .nav-item.open:not(.active) > .nav-link {
  background-color: rgba(151, 186, 247, 0.09);
}

.sidebar-dark .nav > .nav-item > .nav-link:hover {
  background-color: rgba(151, 186, 247, 0.09);
  color: #fff;
}

.sidebar-dark .nav > .nav-item > .nav-link:hover > .nav-icon {
  color: rgba(187, 218, 238, 0.92);
}

.sidebar-dark .nav > .nav-item > .submenu {
  background-color: rgba(0, 0, 0, 0.2);
}

.sidebar-dark .submenu .nav-link {
  color: rgba(255, 255, 255, 0.87);
  font-size: 0.9125rem;
}

.sidebar-dark .submenu .nav-item.open > .nav-link {
  color: rgba(255, 255, 255, 0.95);
}

.sidebar-dark .submenu .nav-item > .nav-link:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.175);
}

.sidebar-dark .submenu .nav-icon {
  color: #92afd0;
  font-size: 1.1875rem;
}

.sidebar-dark .submenu .nav-link:hover > .nav-icon {
  color: #92afd0;
}

.sidebar-dark .submenu .nav-item.open > .nav-link > .nav-icon {
  color: #92afd0;
}

.sidebar-dark .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-dark .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-dark .open > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-dark .nav-item > .nav-link:hover > .caret {
  opacity: 0.82;
}

.sidebar-dark .nav-item.active > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-dark .nav-item.active.open > .nav-link > .caret {
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-dark.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-dark .nav > .nav-item.active > .nav-link {
  color: #e8f0f7;
  background-color: #466884;
}

.sidebar-dark .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-dark .nav > .nav-item.active > .nav-link > .nav-icon {
  color: rgba(255, 255, 255, 0.95);
}

.sidebar-dark .submenu .nav-item.active > .nav-link {
  color: #8ebfe7;
}

.sidebar-dark .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-dark .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-dark.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-dark.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #8ebfe7;
    font-weight: 600;
  }
  .sidebar-dark.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-dark.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

@media (hover: hover) {
  .sidebar-dark .nav > .nav-item > .nav-link:hover::before {
    border-color: rgba(255, 255, 255, 0.75);
  }
}

.sidebar-dark .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: rgba(113, 200, 236, 0.8);
}

.sidebar-dark .nav.has-active-border > .nav-item.active:hover > .nav-link::before {
  border-color: rgba(113, 200, 236, 0.8);
}

@media (hover: hover) {
  .sidebar-dark .nav.has-active-border > .nav-item.active.open > .nav-link:hover::before {
    display: block;
  }
}

.sidebar-dark .submenu .nav-link {
  padding-left: 3.75em;
}

.sidebar-dark .submenu .submenu .nav-link {
  padding-left: 4.675em;
}

.sidebar-dark .submenu .submenu .submenu .nav-link {
  padding-left: 5.325em;
}

.sidebar-dark .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-dark .submenu .submenu .nav-text::before {
  left: 3.675em;
}

.sidebar-dark .submenu .submenu .submenu .nav-text::before {
  left: 4.325em;
}

.sidebar-dark .submenu .nav-item > .nav-link:hover > .nav-text::before {
  content: "";
  display: block;
  opacity: 0.8;
}

.sidebar-dark .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-dark.hoverable.collapsed .submenu .nav-text::before, .sidebar-dark.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

.sidebar-dark .nav-item-caption {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
}

.sidebar-dark .nav-item-divider {
  border-top: 1px solid rgba(208, 217, 221, 0.175);
}

.sidebar-dark .submenu .nav-item-divider {
  border-top-color: rgba(223, 229, 232, 0.175);
}

@media (min-width: 1200px) {
  .sidebar-dark.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-dark.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-dark.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #33414a;
  }
  .sidebar-dark.hoverable.collapsed .sub-arrow {
    border-color: #33414a !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-dark.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-dark.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-dark.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-dark.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #33414a;
  }
  .sidebar-dark.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-dark.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #33414a !important;
  }
}

.sidebar-dark .ace-scroll {
  scrollbar-color: rgba(255, 255, 255, 0.75) rgba(255, 255, 255, 0.33);
}

.sidebar-dark .ace-scroll::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.33);
  border-right: 1px solid #33414a;
}

.sidebar-dark .ace-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.75);
  border-right: 1px solid #33414a;
}

@media (min-width: 1200px) {
  .sidebar-dark.toggling .nav > .nav-item.active.open {
    transition: none;
  }
  .sidebar-dark.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active), .sidebar-dark.hoverable.collapsed .nav > .nav-item.open:not(.active) {
    border-color: transparent;
    background-color: transparent;
  }
  .sidebar-dark.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link, .sidebar-dark.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link {
    color: rgba(255, 255, 255, 0.88);
    background-color: transparent;
  }
  .sidebar-dark.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon, .sidebar-dark.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon {
    color: rgba(187, 218, 238, 0.85);
  }
  .sidebar-dark.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open, .sidebar-dark.hoverable.collapsed .nav > .nav-item.active.open {
    transition: none;
  }
}

.sidebar-dark .sidebar-search-input {
  color: rgba(255, 255, 255, 0.85);
  border-bottom-color: rgba(255, 255, 255, 0.15);
}

.sidebar-dark .sidebar-search-input:focus {
  border-bottom-color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark .sidebar-search-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark .sidebar-search-input::-moz-placeholder {
  color: #fff;
}

.sidebar-purple {
  background-color: #625495;
}

.sidebar-purple .sidebar-inner {
  background: inherit;
}

@media (max-width: 1199.98px) {
  .sidebar-purple:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #625495;
  }
}

.sidebar-purple .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-purple .open > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-purple .nav-item > .nav-link:hover > .caret {
  opacity: 0.82;
}

.sidebar-purple .nav-item.active > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-purple .nav-item.active.open > .nav-link > .caret {
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-purple.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-purple .nav > .nav-item.active > .nav-link {
  background-color: rgba(174, 134, 59, 0.55);
}

.sidebar-purple .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-purple .submenu .nav-item.active > .nav-link {
  color: #e8c177;
}

.sidebar-purple .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-purple.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-purple.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #e8c177;
    font-weight: 600;
  }
  .sidebar-purple.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-purple.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-purple .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: #dbbd59;
}

.sidebar-purple .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.375rem);
  width: 0;
  height: 0;
  border: 0.375rem solid transparent;
  border-left-color: currentColor;
  border-right-width: 0;
}

.sidebar-purple .submenu .submenu .nav-text::before {
  left: 3.25em;
}

.sidebar-purple .submenu .submenu .submenu .nav-text::before {
  left: 4em;
}

.sidebar-purple .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-purple.hoverable.collapsed .submenu .nav-text::before, .sidebar-purple.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-purple.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-purple.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-purple.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #625495;
  }
  .sidebar-purple.hoverable.collapsed .sub-arrow {
    border-color: #625495 !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-purple.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-purple.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-purple.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-purple.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #625495;
  }
  .sidebar-purple.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-purple.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #625495 !important;
  }
}

.sidebar-purple .ace-scroll {
}

.sidebar-purple .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #625495;
}

.sidebar-purple .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #625495;
}

.sidebar-plum {
  background-color: #724c73;
}

.sidebar-plum .sidebar-inner {
  background: inherit;
}

@media (max-width: 1199.98px) {
  .sidebar-plum:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #724c73;
  }
}

.sidebar-plum .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-plum .open > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-plum .nav-item > .nav-link:hover > .caret {
  opacity: 0.82;
}

.sidebar-plum .nav-item.active > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-plum .nav-item.active.open > .nav-link > .caret {
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-plum.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-plum .nav > .nav-item.active > .nav-link {
  background-color: rgba(211, 175, 42, 0.45);
}

.sidebar-plum .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-plum .submenu .nav-item.active > .nav-link {
  color: #dcc286;
}

.sidebar-plum .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-plum.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-plum.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #dcc286;
    font-weight: 600;
  }
  .sidebar-plum.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-plum.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-plum .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: #dbbd59;
}

.sidebar-plum .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.375rem);
  width: 0;
  height: 0;
  border: 0.375rem solid transparent;
  border-left-color: currentColor;
  border-right-width: 0;
}

.sidebar-plum .submenu .submenu .nav-text::before {
  left: 3.25em;
}

.sidebar-plum .submenu .submenu .submenu .nav-text::before {
  left: 4em;
}

.sidebar-plum .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-plum.hoverable.collapsed .submenu .nav-text::before, .sidebar-plum.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-plum.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-plum.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-plum.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #724c73;
  }
  .sidebar-plum.hoverable.collapsed .sub-arrow {
    border-color: #724c73 !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-plum.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-plum.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-plum.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-plum.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #724c73;
  }
  .sidebar-plum.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-plum.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #724c73 !important;
  }
}

.sidebar-plum .ace-scroll {
}

.sidebar-plum .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #724c73;
}

.sidebar-plum .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #724c73;
}

.sidebar-green {
  background-color: #4c7358;
}

.sidebar-green .sidebar-inner {
  background: inherit;
}

@media (max-width: 1199.98px) {
  .sidebar-green:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #4c7358;
  }
}

.sidebar-green .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-green .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-green .open > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-green .nav-item > .nav-link:hover > .caret {
  opacity: 0.82;
}

.sidebar-green .nav-item.active > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-green .nav-item.active.open > .nav-link > .caret {
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-green.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-green .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-green .submenu .nav-item.active > .nav-link {
  color: #e6d170;
}

.sidebar-green .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-green.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-green.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #e6d170;
    font-weight: 600;
  }
  .sidebar-green.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-green.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-green .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: rgba(230, 209, 112, 0.87);
}

.sidebar-green .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-green .submenu .submenu .nav-text::before {
  left: 3.25em;
}

.sidebar-green .submenu .submenu .submenu .nav-text::before {
  left: 4em;
}

.sidebar-green .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-green.hoverable.collapsed .submenu .nav-text::before, .sidebar-green.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-green.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-green.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-green.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #4c7358;
  }
  .sidebar-green.hoverable.collapsed .sub-arrow {
    border-color: #4c7358 !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-green.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-green.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-green.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-green.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #4c7358;
  }
  .sidebar-green.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-green.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #4c7358 !important;
  }
}

.sidebar-green .ace-scroll {
}

.sidebar-green .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #4c7358;
}

.sidebar-green .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #4c7358;
}

.sidebar-blue {
  background-color: #308cc0;
}

.sidebar-blue .sidebar-inner {
  background: inherit;
}

@media (max-width: 1199.98px) {
  .sidebar-blue:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #308cc0;
  }
}

.sidebar-blue .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-blue .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-blue .open > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-blue .nav-item > .nav-link:hover > .caret {
  opacity: 0.82;
}

.sidebar-blue .nav-item.active > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-blue .nav-item.active.open > .nav-link > .caret {
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-blue.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-blue .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-blue .submenu .nav-item.active > .nav-link {
  color: #f9e092;
}

.sidebar-blue .submenu .nav-item.active:not(.open) > .nav-link {
  background-color: rgba(255, 255, 255, 0.09);
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-blue.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-blue.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #f9e092;
    background-color: rgba(255, 255, 255, 0.09);
    font-weight: 600;
  }
}

@media (min-width: 1200px) {
  .sidebar-blue.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-blue.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-blue.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #308cc0;
  }
  .sidebar-blue.hoverable.collapsed .sub-arrow {
    border-color: #308cc0 !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-blue.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-blue.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-blue.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-blue.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #308cc0;
  }
  .sidebar-blue.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-blue.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #308cc0 !important;
  }
}

.sidebar-blue .ace-scroll {
}

.sidebar-blue .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #308cc0;
}

.sidebar-blue .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #308cc0;
}

.sidebar-darkblue {
  background-color: #3d5769;
}

.sidebar-darkblue .sidebar-inner {
  background: inherit;
}

@media (max-width: 1199.98px) {
  .sidebar-darkblue:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #3d5769;
  }
}

.sidebar-darkblue .nav > .nav-item::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -1px;
  left: 1rem;
  right: 1rem;
  height: 0;
  width: auto;
  border: 0px solid rgba(0, 0, 0, 0.07);
  border-width: 0 0 1px 0;
}

@media (min-width: 1200px) {
  .sidebar-darkblue.sidebar-h .nav > .nav-item::after {
    display: none;
    /**
                border-width: 0 $item-border-size 0 0;
                $margin: map-get($sidebar, 'item-border-margin');
                @if $margin == null { $margin: map-get($sidebar-common, 'item-border-margin'); }
                left: auto;
                right: -2px;

                top: $margin * 0.75;
                bottom: $margin * 0.75;

                height: auto;
                width: 0;
                */
  }
}

.sidebar-darkblue .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-darkblue .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-darkblue .open > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-darkblue .nav-item > .nav-link:hover > .caret {
  opacity: 0.82;
}

.sidebar-darkblue .nav-item.active > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-darkblue .nav-item.active.open > .nav-link > .caret {
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-darkblue.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-darkblue .nav > .nav-item.active > .nav-link {
  background-color: rgba(255, 255, 255, 0.125);
}

.sidebar-darkblue .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-darkblue .submenu .nav-item.active > .nav-link {
  color: #8dcfe3;
}

.sidebar-darkblue .submenu .nav-item.active:not(.open) > .nav-link {
  background-color: rgba(0, 0, 0, 0.15);
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-darkblue.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-darkblue.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #8dcfe3;
    background-color: rgba(0, 0, 0, 0.15);
    font-weight: 600;
  }
}

.sidebar-darkblue .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: #81cae0;
}

@media (min-width: 1200px) {
  .sidebar-darkblue.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-darkblue.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-darkblue.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #3d5769;
  }
  .sidebar-darkblue.hoverable.collapsed .sub-arrow {
    border-color: #3d5769 !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-darkblue.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-darkblue.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-darkblue.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-darkblue.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #3d5769;
  }
  .sidebar-darkblue.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-darkblue.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #3d5769 !important;
  }
}

.sidebar-darkblue .ace-scroll {
}

.sidebar-darkblue .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #3d5769;
}

.sidebar-darkblue .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #3d5769;
}

.sidebar-darkcrimson {
  background-color: #734c53;
}

.sidebar-darkcrimson .sidebar-inner {
  background: inherit;
}

@media (max-width: 1199.98px) {
  .sidebar-darkcrimson:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #734c53;
  }
}

.sidebar-darkcrimson .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-darkcrimson .open > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-darkcrimson .nav-item > .nav-link:hover > .caret {
  opacity: 0.82;
}

.sidebar-darkcrimson .nav-item.active > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-darkcrimson .nav-item.active.open > .nav-link > .caret {
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-darkcrimson.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-darkcrimson .nav > .nav-item.active > .nav-link {
  background-color: #77785e;
}

.sidebar-darkcrimson .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-darkcrimson .submenu .nav-item.active > .nav-link {
  color: #c2d8b9;
}

.sidebar-darkcrimson .submenu .nav-item.active:not(.open) > .nav-link {
  background-color: rgba(255, 255, 255, 0.09);
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-darkcrimson.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-darkcrimson.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #c2d8b9;
    background-color: rgba(255, 255, 255, 0.09);
    font-weight: 600;
  }
}

@media (min-width: 1200px) {
  .sidebar-darkcrimson.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-darkcrimson.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-darkcrimson.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #734c53;
  }
  .sidebar-darkcrimson.hoverable.collapsed .sub-arrow {
    border-color: #734c53 !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-darkcrimson.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-darkcrimson.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-darkcrimson.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-darkcrimson.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #734c53;
  }
  .sidebar-darkcrimson.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-darkcrimson.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #734c53 !important;
  }
}

.sidebar-darkcrimson .ace-scroll {
}

.sidebar-darkcrimson .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #734c53;
}

.sidebar-darkcrimson .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #734c53;
}

.sidebar-darkslategrey {
  background-color: darkslategrey;
}

.sidebar-darkslategrey .sidebar-inner {
  background: inherit;
}

@media (max-width: 1199.98px) {
  .sidebar-darkslategrey:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid darkslategrey;
  }
}

.sidebar-darkslategrey .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-darkslategrey .open > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-darkslategrey .nav-item > .nav-link:hover > .caret {
  opacity: 0.82;
}

.sidebar-darkslategrey .nav-item.active > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-darkslategrey .nav-item.active.open > .nav-link > .caret {
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-darkslategrey.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-darkslategrey .nav > .nav-item.active > .nav-link {
  background-color: rgba(136, 192, 60, 0.5);
}

.sidebar-darkslategrey .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-darkslategrey .submenu .nav-item.active > .nav-link {
  color: #b6da88;
}

.sidebar-darkslategrey .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-darkslategrey.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-darkslategrey.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #b6da88;
    font-weight: 600;
  }
  .sidebar-darkslategrey.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-darkslategrey.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-darkslategrey .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-darkslategrey .submenu .submenu .nav-text::before {
  left: 3.25em;
}

.sidebar-darkslategrey .submenu .submenu .submenu .nav-text::before {
  left: 4em;
}

.sidebar-darkslategrey .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-darkslategrey.hoverable.collapsed .submenu .nav-text::before, .sidebar-darkslategrey.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-darkslategrey.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-darkslategrey.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-darkslategrey.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: darkslategrey;
  }
  .sidebar-darkslategrey.hoverable.collapsed .sub-arrow {
    border-color: darkslategrey !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-darkslategrey.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-darkslategrey.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-darkslategrey.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-darkslategrey.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: darkslategrey;
  }
  .sidebar-darkslategrey.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-darkslategrey.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: darkslategrey !important;
  }
}

.sidebar-darkslategrey .ace-scroll {
}

.sidebar-darkslategrey .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid darkslategrey;
}

.sidebar-darkslategrey .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid darkslategrey;
}

.sidebar-darkslateblue {
  background-color: #524a87;
}

.sidebar-darkslateblue .sidebar-inner {
  background: inherit;
}

@media (max-width: 1199.98px) {
  .sidebar-darkslateblue:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #524a87;
  }
}

.sidebar-darkslateblue .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-darkslateblue .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-darkslateblue .open > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-darkslateblue .nav-item > .nav-link:hover > .caret {
  opacity: 0.82;
}

.sidebar-darkslateblue .nav-item.active > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-darkslateblue .nav-item.active.open > .nav-link > .caret {
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-darkslateblue.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-darkslateblue .nav > .nav-item.active > .nav-link {
  background-color: #a19c2c;
}

.sidebar-darkslateblue .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-darkslateblue .submenu .nav-item.active > .nav-link {
  color: #e3dd65;
}

.sidebar-darkslateblue .submenu .nav-item.active:not(.open) > .nav-link {
  background-color: rgba(255, 255, 255, 0.1);
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-darkslateblue.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-darkslateblue.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #e3dd65;
    background-color: rgba(255, 255, 255, 0.1);
    font-weight: 600;
  }
}

@media (hover: hover) {
  .sidebar-darkslateblue .nav > .nav-item > .nav-link:hover::before {
    border-color: rgba(255, 255, 255, 0.8);
  }
}

.sidebar-darkslateblue .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: rgba(255, 255, 255, 0.93);
}

.sidebar-darkslateblue .nav.has-active-border > .nav-item.active:hover > .nav-link::before {
  border-color: rgba(255, 255, 255, 0.93);
}

@media (hover: hover) {
  .sidebar-darkslateblue .nav.has-active-border > .nav-item.active.open > .nav-link:hover::before {
    display: block;
  }
}

@media (min-width: 1200px) {
  .sidebar-darkslateblue.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-darkslateblue.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-darkslateblue.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #524a87;
  }
  .sidebar-darkslateblue.hoverable.collapsed .sub-arrow {
    border-color: #524a87 !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-darkslateblue.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-darkslateblue.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-darkslateblue.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-darkslateblue.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #524a87;
  }
  .sidebar-darkslateblue.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-darkslateblue.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #524a87 !important;
  }
}

.sidebar-darkslateblue .ace-scroll {
}

.sidebar-darkslateblue .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #524a87;
}

.sidebar-darkslateblue .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #524a87;
}

.sidebar-teal {
  background-color: #008080;
}

.sidebar-teal .sidebar-inner {
  background: inherit;
}

@media (max-width: 1199.98px) {
  .sidebar-teal:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #008080;
  }
}

.sidebar-teal .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-teal .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-teal .open > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-teal .nav-item > .nav-link:hover > .caret {
  opacity: 0.82;
}

.sidebar-teal .nav-item.active > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-teal .nav-item.active.open > .nav-link > .caret {
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-teal.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-teal .nav > .nav-item.active > .nav-link {
  background-color: #b98c11;
}

.sidebar-teal .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-teal .submenu .nav-item.active > .nav-link {
  color: #ffc551;
}

.sidebar-teal .submenu .nav-item.active:not(.open) > .nav-link {
  background-color: rgba(255, 255, 255, 0.1);
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-teal.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-teal.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #ffc551;
    background-color: rgba(255, 255, 255, 0.1);
    font-weight: 600;
  }
}

@media (hover: hover) {
  .sidebar-teal .nav > .nav-item > .nav-link:hover::before {
    border-color: rgba(255, 255, 255, 0.8);
  }
}

.sidebar-teal .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: rgba(255, 255, 255, 0.93);
}

.sidebar-teal .nav.has-active-border > .nav-item.active:hover > .nav-link::before {
  border-color: rgba(255, 255, 255, 0.93);
}

@media (hover: hover) {
  .sidebar-teal .nav.has-active-border > .nav-item.active.open > .nav-link:hover::before {
    display: block;
  }
}

@media (min-width: 1200px) {
  .sidebar-teal.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-teal.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-teal.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #008080;
  }
  .sidebar-teal.hoverable.collapsed .sub-arrow {
    border-color: #008080 !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-teal.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-teal.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-teal.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-teal.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #008080;
  }
  .sidebar-teal.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-teal.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #008080 !important;
  }
}

.sidebar-teal .ace-scroll {
}

.sidebar-teal .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #008080;
}

.sidebar-teal .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #008080;
}

.sidebar-steelblue {
  background-color: #4682b4;
}

.sidebar-steelblue .sidebar-inner {
  background: inherit;
}

@media (max-width: 1199.98px) {
  .sidebar-steelblue:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #4682b4;
  }
}

.sidebar-steelblue .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-steelblue .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-steelblue .open > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-steelblue .nav-item > .nav-link:hover > .caret {
  opacity: 0.82;
}

.sidebar-steelblue .nav-item.active > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-steelblue .nav-item.active.open > .nav-link > .caret {
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-steelblue.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-steelblue .nav > .nav-item.active > .nav-link {
  background-color: rgba(211, 168, 32, 0.75);
}

.sidebar-steelblue .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-steelblue .submenu .nav-item.active > .nav-link {
  color: #f9e092;
}

.sidebar-steelblue .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-steelblue.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-steelblue.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #f9e092;
    font-weight: 600;
  }
  .sidebar-steelblue.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-steelblue.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

@media (hover: hover) {
  .sidebar-steelblue .nav > .nav-item > .nav-link:hover::before {
    border-color: rgba(255, 255, 255, 0.8);
  }
}

.sidebar-steelblue .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: rgba(255, 255, 255, 0.93);
}

.sidebar-steelblue .nav.has-active-border > .nav-item.active:hover > .nav-link::before {
  border-color: rgba(255, 255, 255, 0.93);
}

@media (hover: hover) {
  .sidebar-steelblue .nav.has-active-border > .nav-item.active.open > .nav-link:hover::before {
    display: block;
  }
}

.sidebar-steelblue .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-steelblue .submenu .submenu .nav-text::before {
  left: 3.25em;
}

.sidebar-steelblue .submenu .submenu .submenu .nav-text::before {
  left: 4em;
}

.sidebar-steelblue .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-steelblue.hoverable.collapsed .submenu .nav-text::before, .sidebar-steelblue.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-steelblue.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-steelblue.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-steelblue.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #4682b4;
  }
  .sidebar-steelblue.hoverable.collapsed .sub-arrow {
    border-color: #4682b4 !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-steelblue.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-steelblue.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-steelblue.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-steelblue.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #4682b4;
  }
  .sidebar-steelblue.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-steelblue.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #4682b4 !important;
  }
}

.sidebar-steelblue .ace-scroll {
}

.sidebar-steelblue .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #4682b4;
}

.sidebar-steelblue .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #4682b4;
}

.sidebar-cadetblue {
  background-color: #447e80;
}

.sidebar-cadetblue .sidebar-inner {
  background: inherit;
}

@media (max-width: 1199.98px) {
  .sidebar-cadetblue:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #447e80;
  }
}

.sidebar-cadetblue .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-cadetblue .nav > .nav-item > .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.sidebar-cadetblue .nav > .nav-item > .nav-link > .nav-icon {
  color: rgba(255, 255, 255, 0.8);
}

.sidebar-cadetblue .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-cadetblue .open > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-cadetblue .nav-item > .nav-link:hover > .caret {
  opacity: 0.82;
}

.sidebar-cadetblue .nav-item.active > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-cadetblue .nav-item.active.open > .nav-link > .caret {
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-cadetblue.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-cadetblue .nav > .nav-item.active > .nav-link {
  background-color: rgba(0, 0, 0, 0.3);
}

.sidebar-cadetblue .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-cadetblue .submenu .nav-item.active > .nav-link {
  color: #fed5a1;
}

.sidebar-cadetblue .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-cadetblue.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-cadetblue.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #fed5a1;
    font-weight: 600;
  }
  .sidebar-cadetblue.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-cadetblue.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

@media (hover: hover) {
  .sidebar-cadetblue .nav > .nav-item > .nav-link:hover::before {
    border-color: rgba(255, 255, 255, 0.73);
  }
}

.sidebar-cadetblue .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: #f4b76a;
}

.sidebar-cadetblue .nav.has-active-border > .nav-item.active:hover > .nav-link::before {
  border-color: #f4b76a;
}

@media (hover: hover) {
  .sidebar-cadetblue .nav.has-active-border > .nav-item.active.open > .nav-link:hover::before {
    display: block;
  }
}

.sidebar-cadetblue .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-cadetblue .submenu .submenu .nav-text::before {
  left: 3.25em;
}

.sidebar-cadetblue .submenu .submenu .submenu .nav-text::before {
  left: 4em;
}

.sidebar-cadetblue .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-cadetblue.hoverable.collapsed .submenu .nav-text::before, .sidebar-cadetblue.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-cadetblue.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-cadetblue.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-cadetblue.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #447e80;
  }
  .sidebar-cadetblue.hoverable.collapsed .sub-arrow {
    border-color: #447e80 !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-cadetblue.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-cadetblue.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-cadetblue.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-cadetblue.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #447e80;
  }
  .sidebar-cadetblue.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-cadetblue.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #447e80 !important;
  }
}

.sidebar-cadetblue .ace-scroll {
}

.sidebar-cadetblue .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #447e80;
}

.sidebar-cadetblue .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #447e80;
}

.sidebar-gradient1 {
  background-color: #3b6471;
  background-image: linear-gradient(seagreen, darkslateblue);
}

.sidebar-gradient1 .sidebar-inner {
  background: inherit;
}

@media (max-width: 1199.98px) {
  .sidebar-gradient1:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #3b6471;
  }
}

.sidebar-gradient1 .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-gradient1 .nav > .nav-item > .nav-link > .nav-icon {
  color: rgba(255, 255, 255, 0.66);
}

.sidebar-gradient1 .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-gradient1 .open > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-gradient1 .nav-item > .nav-link:hover > .caret {
  opacity: 0.82;
}

.sidebar-gradient1 .nav-item.active > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-gradient1 .nav-item.active.open > .nav-link > .caret {
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-gradient1.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-gradient1 .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-gradient1 .submenu .nav-item.active > .nav-link {
  color: #f5e685;
}

.sidebar-gradient1 .submenu .nav-item.active:not(.open) > .nav-link {
  background-color: rgba(255, 255, 255, 0.09);
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-gradient1.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-gradient1.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #f5e685;
    background-color: rgba(255, 255, 255, 0.09);
    font-weight: 600;
  }
}

@media (min-width: 1200px) {
  .sidebar-gradient1.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-gradient1.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-gradient1.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #3b6471;
  }
  .sidebar-gradient1.hoverable.collapsed .sub-arrow {
    border-color: #3b6471 !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-gradient1.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-gradient1.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-gradient1.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-gradient1.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #3b6471;
  }
  .sidebar-gradient1.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-gradient1.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #3b6471 !important;
  }
}

.sidebar-gradient1 .ace-scroll {
}

.sidebar-gradient1 .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #3b6471;
}

.sidebar-gradient1 .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #3b6471;
}

.sidebar-gradient2 {
  background-color: #3b6471;
  background-image: linear-gradient(darkslateblue, seagreen);
}

.sidebar-gradient2 .sidebar-inner {
  background: inherit;
}

@media (max-width: 1199.98px) {
  .sidebar-gradient2:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #3b6471;
  }
}

.sidebar-gradient2 .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-gradient2 .nav > .nav-item > .nav-link > .nav-icon {
  color: rgba(255, 255, 255, 0.66);
}

.sidebar-gradient2 .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-gradient2 .open > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-gradient2 .nav-item > .nav-link:hover > .caret {
  opacity: 0.82;
}

.sidebar-gradient2 .nav-item.active > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-gradient2 .nav-item.active.open > .nav-link > .caret {
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-gradient2.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-gradient2 .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-gradient2 .submenu .nav-item.active > .nav-link {
  color: #f5e685;
}

.sidebar-gradient2 .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-gradient2.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-gradient2.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #f5e685;
    font-weight: 600;
  }
  .sidebar-gradient2.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-gradient2.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-gradient2 .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: #f6e9af;
}

.sidebar-gradient2 .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-gradient2 .submenu .submenu .nav-text::before {
  left: 3.25em;
}

.sidebar-gradient2 .submenu .submenu .submenu .nav-text::before {
  left: 4em;
}

.sidebar-gradient2 .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-gradient2.hoverable.collapsed .submenu .nav-text::before, .sidebar-gradient2.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-gradient2.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-gradient2.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-gradient2.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #3b6471;
  }
  .sidebar-gradient2.hoverable.collapsed .sub-arrow {
    border-color: #3b6471 !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-gradient2.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-gradient2.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-gradient2.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-gradient2.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #3b6471;
  }
  .sidebar-gradient2.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-gradient2.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #3b6471 !important;
  }
}

.sidebar-gradient2 .ace-scroll {
}

.sidebar-gradient2 .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #3b6471;
}

.sidebar-gradient2 .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #3b6471;
}

.sidebar-gradient3 {
  background-color: #555762;
  background-image: linear-gradient(#4c5a73, #665041);
}

.sidebar-gradient3 .sidebar-inner {
  background: inherit;
}

@media (max-width: 1199.98px) {
  .sidebar-gradient3:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #555762;
  }
}

.sidebar-gradient3 .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-gradient3 .nav > .nav-item > .nav-link > .nav-icon {
  color: rgba(255, 255, 255, 0.66);
}

.sidebar-gradient3 .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-gradient3 .open > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-gradient3 .nav-item > .nav-link:hover > .caret {
  opacity: 0.82;
}

.sidebar-gradient3 .nav-item.active > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-gradient3 .nav-item.active.open > .nav-link > .caret {
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-gradient3.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-gradient3 .nav > .nav-item.active > .nav-link {
  color: #f6e9af;
}

.sidebar-gradient3 .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-gradient3 .nav > .nav-item.active > .nav-link > .nav-icon {
  color: #f6e9af;
}

.sidebar-gradient3 .submenu .nav-item.active > .nav-link {
  color: rgba(245, 225, 133, 0.9);
}

.sidebar-gradient3 .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-gradient3.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-gradient3.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: rgba(245, 225, 133, 0.9);
    font-weight: 600;
  }
  .sidebar-gradient3.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-gradient3.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-gradient3 .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: #f6e9af;
}

.sidebar-gradient3 .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.375rem);
  width: 0;
  height: 0;
  border: 0.375rem solid transparent;
  border-left-color: currentColor;
  border-right-width: 0;
}

.sidebar-gradient3 .submenu .submenu .nav-text::before {
  left: 3.25em;
}

.sidebar-gradient3 .submenu .submenu .submenu .nav-text::before {
  left: 4em;
}

.sidebar-gradient3 .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-gradient3.hoverable.collapsed .submenu .nav-text::before, .sidebar-gradient3.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-gradient3.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-gradient3.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-gradient3.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #555762;
  }
  .sidebar-gradient3.hoverable.collapsed .sub-arrow {
    border-color: #555762 !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-gradient3.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-gradient3.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-gradient3.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-gradient3.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #555762;
  }
  .sidebar-gradient3.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-gradient3.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #555762 !important;
  }
}

.sidebar-gradient3 .ace-scroll {
}

.sidebar-gradient3 .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #555762;
}

.sidebar-gradient3 .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #555762;
}

.sidebar-gradient4 {
  background-color: #425a86;
  background-image: linear-gradient(#586b8c, #163a7b);
}

.sidebar-gradient4 .sidebar-inner {
  background: inherit;
}

@media (max-width: 1199.98px) {
  .sidebar-gradient4:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #425a86;
  }
}

.sidebar-gradient4 .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-gradient4 .nav > .nav-item > .nav-link > .nav-icon {
  color: rgba(255, 255, 255, 0.66);
}

.sidebar-gradient4 .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-gradient4 .open > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-gradient4 .nav-item > .nav-link:hover > .caret {
  opacity: 0.82;
}

.sidebar-gradient4 .nav-item.active > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-gradient4 .nav-item.active.open > .nav-link > .caret {
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-gradient4.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-gradient4 .nav > .nav-item.active > .nav-link {
  color: #fff;
  background-color: rgba(59, 147, 198, 0.52);
}

.sidebar-gradient4 .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-gradient4 .nav > .nav-item.active > .nav-link > .nav-icon {
  color: rgba(255, 255, 255, 0.87);
}

.sidebar-gradient4 .submenu .nav-item.active > .nav-link {
  color: #baddf7;
}

.sidebar-gradient4 .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-gradient4.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-gradient4.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #baddf7;
    font-weight: 600;
  }
  .sidebar-gradient4.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-gradient4.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-gradient4 .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.375rem);
  width: 0;
  height: 0;
  border: 0.375rem solid transparent;
  border-left-color: currentColor;
  border-right-width: 0;
}

.sidebar-gradient4 .submenu .submenu .nav-text::before {
  left: 3.25em;
}

.sidebar-gradient4 .submenu .submenu .submenu .nav-text::before {
  left: 4em;
}

.sidebar-gradient4 .submenu .nav-item > .nav-link:hover > .nav-text::before {
  content: "";
  display: block;
  opacity: 0.8;
}

.sidebar-gradient4 .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-gradient4.hoverable.collapsed .submenu .nav-text::before, .sidebar-gradient4.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-gradient4.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-gradient4.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-gradient4.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #425a86;
  }
  .sidebar-gradient4.hoverable.collapsed .sub-arrow {
    border-color: #425a86 !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-gradient4.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-gradient4.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-gradient4.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-gradient4.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #425a86;
  }
  .sidebar-gradient4.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-gradient4.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #425a86 !important;
  }
}

.sidebar-gradient4 .ace-scroll {
}

.sidebar-gradient4 .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #425a86;
}

.sidebar-gradient4 .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #425a86;
}

.sidebar-gradient5 {
  background-color: #846786;
  background-image: linear-gradient(#8057a7, #8c8645);
}

.sidebar-gradient5 .sidebar-inner {
  background: inherit;
}

@media (max-width: 1199.98px) {
  .sidebar-gradient5:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #846786;
  }
}

.sidebar-gradient5 .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-gradient5 .nav > .nav-item > .nav-link > .nav-icon {
  color: rgba(255, 255, 255, 0.66);
}

.sidebar-gradient5 .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-gradient5 .open > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-gradient5 .nav-item > .nav-link:hover > .caret {
  opacity: 0.82;
}

.sidebar-gradient5 .nav-item.active > .nav-link > .caret {
  opacity: 0.82;
}

.sidebar-gradient5 .nav-item.active.open > .nav-link > .caret {
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-gradient5.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-gradient5 .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-gradient5 .submenu .nav-item.active > .nav-link {
  color: #f5e685;
}

.sidebar-gradient5 .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-gradient5.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-gradient5.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #f5e685;
    font-weight: 600;
  }
  .sidebar-gradient5.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-gradient5.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-gradient5 .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-gradient5 .submenu .submenu .nav-text::before {
  left: 3.25em;
}

.sidebar-gradient5 .submenu .submenu .submenu .nav-text::before {
  left: 4em;
}

.sidebar-gradient5 .submenu .nav-item > .nav-link:hover > .nav-text::before {
  content: "";
  display: block;
  opacity: 0.8;
}

.sidebar-gradient5 .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-gradient5.hoverable.collapsed .submenu .nav-text::before, .sidebar-gradient5.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-gradient5.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-gradient5.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-gradient5.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #846786;
  }
  .sidebar-gradient5.hoverable.collapsed .sub-arrow {
    border-color: #846786 !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-gradient5.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-gradient5.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-gradient5.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-gradient5.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #846786;
  }
  .sidebar-gradient5.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-gradient5.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #846786 !important;
  }
}

.sidebar-gradient5 .ace-scroll {
}

.sidebar-gradient5 .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #846786;
}

.sidebar-gradient5 .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #846786;
}

@media (min-width: 1200px) {
  .sidebar-color.hoverable.collapsed .submenu .submenu,
  .sidebar-color.hoverable.collapsed .submenu-inner,
  .sidebar-color.hoverable.collapsed .submenu .nav-item {
    background-color: inherit !important;
  }
  .sidebar-color.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text {
    color: rgba(255, 255, 255, 0.93) !important;
  }
  .sidebar-color.hoverable.collapsed .nav > .nav-item > .submenu {
    top: calc(100% - 2px);
  }
  .sidebar-color.hoverable.collapsed .sub-arrow::before {
    border-right-color: rgba(255, 255, 255, 0.33) !important;
  }
  .sidebar-color.hoverable.collapsed .sub-arrow::after {
    border-right-color: inherit !important;
  }
  .sidebar-color.hoverable.collapsed .sidebar-section-item .fadeable {
    border: 1px solid;
    box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.1);
  }
  .sidebar-color.hoverable.collapsed .submenu,
  .sidebar-color.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-color.hoverable.collapsed .sidebar-section-item .fadeable {
    border-color: rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13) rgba(255, 255, 255, 0.2);
  }
  .sidebar-color.hoverable.collapsed .nav > .nav-item > .submenu {
    border-top-color: rgba(255, 255, 255, 0.2);
  }
  .sidebar-color.hoverable.collapsed .submenu .nav-text::before {
    display: none;
  }
  .sidebar-color.sidebar-hover:not(.collapsed) .submenu .submenu,
  .sidebar-color.sidebar-hover:not(.collapsed) .submenu-inner,
  .sidebar-color.sidebar-hover:not(.collapsed) .submenu .nav-item {
    background-color: inherit !important;
  }
  .sidebar-color.sidebar-hover:not(.collapsed) .sub-arrow::before {
    border-right-color: rgba(255, 255, 255, 0.33) !important;
  }
  .sidebar-color.sidebar-hover:not(.collapsed) .sub-arrow::after {
    border-right-color: inherit !important;
  }
  .sidebar-color.sidebar-hover:not(.collapsed) .submenu {
    border-color: rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13) rgba(255, 255, 255, 0.2);
  }
  .sidebar-color.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    display: none;
  }
  .sidebar-color.sidebar-h .nav > .nav-item > .submenu,
  .sidebar-color.sidebar-h .sidebar-section-item .fadeable {
    border: 1px solid;
    border-color: rgba(255, 255, 255, 0.2) rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13);
    box-shadow: none !important;
  }
  .sidebar-color.sidebar-h .nav > .nav-item > .sub-arrow::before,
  .sidebar-color.sidebar-h .sidebar-section-item .sub-arrow::before {
    border-right-color: transparent !important;
    border-bottom-color: inherit !important;
  }
  .sidebar-color.sidebar-h .nav > .nav-item > .sub-arrow::after,
  .sidebar-color.sidebar-h .sidebar-section-item .sub-arrow::after {
    border-right-color: transparent !important;
    border-bottom-color: rgba(255, 255, 255, 0.33) !important;
  }
}

.body-img1 {
  background-color: #424862;
  background-image: url("/assets/image/body-bg-1.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.body-img2 {
  background-color: #272f53;
  background-image: url("/assets/image/body-bg-2.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.body-darkblue {
  background-color: #465a6e;
}

.body-darkblue .body-container.container {
  box-shadow: 0 -1px 0 1px #415366;
}

.body-darkblue2 {
  background-color: rgba(43, 63, 69, 0.67);
}

.body-darkblue2 .body-container.container {
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.07);
}

.body-darkblue3 {
  background-color: #45556f;
}

.body-darkblue3 .body-container.container {
  box-shadow: 0 -1px 0 1px rgba(31, 81, 123, 0.33);
}

.body-lightblue {
  background-color: #E1EBF0;
}

.body-lightblue .body-container.container {
  box-shadow: 0 0 2px 1px rgba(194, 207, 210, 0.5);
}

.body-lightblue2 {
  background-color: #C8DBE5;
}

.body-lightblue2 .body-container.container {
  box-shadow: 0 0 0 1px rgba(111, 121, 123, 0.73);
}

.body-lightblue3 {
  background-color: #c6cdd8;
}

.body-lightblue3 .body-container.container {
  box-shadow: 0 0 0 1px rgba(31, 81, 123, 0.1);
}

.body-lightblue4 {
  background-color: #c0d2d0;
}

.body-lightblue4 .body-container.container {
  box-shadow: none;
}

.body-darkslategrey {
  background-color: #315555;
}

.body-darkslategrey .body-container.container {
  box-shadow: 0 0 0 1px rgba(80, 123, 123, 0.2);
}

.body-darkplum {
  background-color: rgba(74, 68, 74, 0.94);
}

.body-darkplum .body-container.container {
  box-shadow: 0 -1px 0 1px rgba(69, 56, 68, 0.55);
}

.body-lightplum {
  background-color: #c3c0d2;
}

.body-lightplum .body-container.container {
  box-shadow: 0 -1px 0 1px rgba(69, 56, 68, 0.11);
}

.body-darkgreen {
  background-color: #4a5440;
}

.body-darkgreen .body-container.container {
  box-shadow: 0 -1px 0 1px rgba(69, 56, 68, 0.17);
}

.body-lightgreen {
  background-color: #c7d2c0;
}

.body-lightgreen .body-container.container {
  box-shadow: 0 -1px 0 1px rgba(60, 69, 56, 0.11);
}

.content-bg1 {
  background-color: #f5f7fa;
}

.content-bg1 .nav.has-active-arrow .nav-item.active > .nav-link::after {
  border-right-color: #f5f7fa;
}

/*# sourceMappingURL=ace-themes.css.map */