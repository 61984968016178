/*!
 * Ace Admin Template v2.1.3
 * Copyright 2013-2020
 * You need a commercial license to use this product
 * https://bit.ly/35ciMLb
 */
 
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

@import "variables";
@import "functions";
@import "variables-more";

@import "features";


@import "navbar-themes-variables";
@import "navbar-theme-maker";

@import "sidebar-themes-variables";
@import "sidebar-theme-maker";



$navbar-themes: (
    "default": $navbar-default,
    
    "white": $navbar-white,
    "white2": $navbar-white2,
    "light": $navbar-light,
    "lightblue": $navbar-lightblue,
    "lightpurple": $navbar-lightpurple,
    "lightgreen": $navbar-lightgreen,
    "lightgrey": $navbar-lightgrey,
    "lightyellow": $navbar-lightyellow,
    "khaki": $navbar-khaki,


    "dark": $navbar-dark,
    "color": $navbar-color,

    "purple": $navbar-purple,
    "plum": $navbar-plum,

    "secondary": $navbar-secondary,

    "teal": $navbar-teal,
    "steelblue": $navbar-steelblue,

    "slategrey": $navbar-slategrey,
    "mediumseagreen": $navbar-mediumseagreen,
    "cadetblue": $navbar-cadetblue,
    "darkseagreen": $navbar-darkseagreen,
    "lightslategrey": $navbar-lightslategrey,

    "orange": $navbar-orange,
    "burlywood": $navbar-burlywood,
    "skyblue": $navbar-skyblue,
    "blue": $navbar-blue
);
@each $theme-name, $theme-values in $navbar-themes {
    @include navbar-colors($theme-name, $theme-values);
}


$sidebar-themes: (
    "default": $sidebar-default,
    "light": $sidebar-light,
    "white": $sidebar-white,
    "white2": $sidebar-white2,
    "white3": $sidebar-white3,
    
    "lightpurple": $sidebar-lightpurple,
    "lightblue": $sidebar-lightblue,
    "lightblue2": $sidebar-lightblue2,
    
    "color": $sidebar-color,
    "dark": $sidebar-dark,

    "purple": $sidebar-purple,
    "plum": $sidebar-plum, 

    "green": $sidebar-green,
    "blue": $sidebar-blue,
    "darkblue": $sidebar-darkblue,
    "darkcrimson": $sidebar-darkcrimson,
    "darkslategrey": $sidebar-darkslategrey,
    "darkslateblue": $sidebar-darkslateblue,
    "teal": $sidebar-teal,
    "steelblue": $sidebar-steelblue,
    "cadetblue": $sidebar-cadetblue,  

    "gradient1": $sidebar-gradient1,
    "gradient2": $sidebar-gradient2,
    "gradient3": $sidebar-gradient3,
    "gradient4": $sidebar-gradient4,
    "gradient5": $sidebar-gradient5
);
@each $theme-name, $theme-values in $sidebar-themes {
    @include sidebar-colors($theme-name, $theme-values);
}






@include media-breakpoint-up($sidebar-desktop-min-width) {

    //for dark colored sidebars, submenus should inherit sidebar color
    @if $hoverable == true {
        .sidebar-color.hoverable.collapsed {
            .submenu .submenu,
            .submenu-inner,
            .submenu .nav-item
            //.submenu .nav-link,
            //.submenu .nav-text
            {
                background-color: inherit !important;
            }
            .nav > .nav-item > .nav-link > .nav-text {
                color: rgba(255, 255, 255, 0.93) !important;
            }

            .nav > .nav-item > .submenu {
                top: calc(100% - 2px);// move it a little higher to look better when fading in/out
            }

        
            .sub-arrow {
                &::before {
                    border-right-color: rgba(255, 255, 255, 0.33) !important;
                }
                &::after {
                    border-right-color: inherit !important;
                }
            }
        
            .sidebar-section-item .fadeable {
                border: 1px solid;
                box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.1);
            }

            .submenu,
            .nav > .nav-item > .nav-link > .nav-text,
            .sidebar-section-item .fadeable {        
                border-color: rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13) rgba(255, 255, 255, 0.2);       
            }

            .nav > .nav-item > .submenu {
                border-top-color: rgba(255, 255, 255, 0.2);
            }

            // hide the submenu bullets?
            .submenu .nav-text::before {
                display: none;
            }
        }// .sidebar-color.hoverable
    }// @if hoverable


    @if $sidebar-hover == true {
        .sidebar-color.sidebar-hover:not(.collapsed) {
            .submenu .submenu,
            .submenu-inner,
            .submenu .nav-item {
                background-color: inherit !important;
            }

            .sub-arrow {
                &::before {
                    border-right-color: rgba(255, 255, 255, 0.33) !important;
                }
                &::after {
                    border-right-color: inherit !important;
                }
            }

            .submenu {
                border-color: rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13) rgba(255, 255, 255, 0.2);
            }

            // hide the submenu bullets?
            .submenu .nav-text::before {
                display: none;
            }
        }
    }

    @if $horizontal == true {
        .sidebar-color.sidebar-h {
            .nav > .nav-item > .submenu ,
            .sidebar-section-item .fadeable {
                border: 1px solid;
                border-color: rgba(255, 255, 255, 0.2) rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13) ;
                box-shadow: none !important;
            }
            
            .nav > .nav-item > .sub-arrow ,
            .sidebar-section-item .sub-arrow {
                &::before {
                    border-right-color: transparent !important;
                    border-bottom-color: inherit !important;
                }
                &::after {
                    border-right-color: transparent !important;
                    border-bottom-color: rgba(255, 255, 255, 0.33) !important;
                }
            }
        }
    }
}//media



@if $body-bg-themes == true {
    .body-img1 {
        background-color: #424862;
        background-image: url('/assets/image/body-bg-1.jpg');
        background-attachment: fixed;
        background-repeat: no-repeat;
        //background-size: cover;
    }
    .body-img2 {
        background-color: #272f53;
        background-image: url('/assets/image/body-bg-2.jpg');
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .body-darkblue {
        background-color: #465a6e;

        .body-container.container {
            box-shadow: 0 -1px 0 1px #415366;
        }
    }
    .body-darkblue2 {
        background-color: rgba(43, 63, 69, 0.67);
        .body-container.container {
            //box-shadow: 0 -1px 0 1px rgba(77, 88, 91, 0.73);
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.07);
        }
    }
    .body-darkblue3 {
        background-color: #45556f;
        .body-container.container {
            box-shadow: 0 -1px 0 1px rgba(31, 81, 123, 0.33);
        }
    }
    .body-lightblue {
        background-color: #E1EBF0;
        .body-container.container {
            box-shadow: 0 0 2px 1px rgba(194, 207, 210, 0.5);
        }
    }
    .body-lightblue2 {
        background-color: #C8DBE5;
        .body-container.container {
            box-shadow: 0 0 0 1px rgba(111, 121, 123, 0.73);
        }
    }
    .body-lightblue3 {
        background-color: #c6cdd8;
        .body-container.container {
            box-shadow: 0 0 0 1px rgba(31, 81, 123, 0.1);
        }
    }
    .body-lightblue4 {
        background-color: #c0d2d0;
        .body-container.container {
            box-shadow: none;
        }
    }

    .body-darkslategrey {
        background-color: #315555;

        .body-container.container {
            box-shadow: 0 0 0 1px rgba(80, 123, 123, 0.2);
        }
    }
    .body-darkplum {
        background-color: rgba(74, 68, 74, 0.94);//rgba(80, 63, 78, 0.94);

        .body-container.container {
            box-shadow: 0 -1px 0 1px rgba(69, 56, 68, 0.55);
        }
    }
    .body-lightplum {
        background-color: #c3c0d2;

        .body-container.container {
            box-shadow: 0 -1px 0 1px rgba(69, 56, 68, 0.11);
        }
    }

    .body-darkgreen {
        background-color: #4a5440;

        .body-container.container {
            box-shadow: 0 -1px 0 1px rgba(69, 56, 68, 0.17);
        }
    }
    .body-lightgreen {
        background-color: #c7d2c0;

        .body-container.container {
            box-shadow: 0 -1px 0 1px rgba(60, 69, 56, 0.11);
        }
    }
}

.content-bg1 {
    background-color: #f5f7fa;
    .nav.has-active-arrow .nav-item.active > .nav-link::after {
        border-right-color: #f5f7fa;
    }
}